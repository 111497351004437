import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyStub} from '../../generated/api-stubs';
import {SimType} from '../visualizations/sim-type';
import {UrlFileLoader} from '../visualizations/url-file-loader';
import {NavigationStation} from '../visualizations/navigation-station/navigation-station';
import {GetUsernameMap, UsernameMap} from '../simulations/get-username-map.service';
import {
  TenantStudyStatisticsUtilities,
  RawStatistics, ProcessedStatistics, RawStatisticsPeriodItem
} from './tenant-study-statistics-utilities.service';
import {CanopySiteHooks, CanopySiteHooksFactory} from '../simulations/visualizations/canopy-site-hooks.service';
import {CanopyFileLoader, CanopyFileLoaderFactory} from '../simulations/visualizations/canopy-file-loader.service';
import {NavigationStationConfig} from '../visualizations/navigation-station/config-builders/navigation-station-config-builder';
import {ConfigBuilderBase} from '../visualizations/navigation-station/config-builders/config-builder-base';
import {ChannelNameStyle} from '../visualizations/viewers/channel-data-loaders/channel-name-style';
import {SharedState} from '../visualizations/viewers/shared-state';
import {LinePlotViewer, POINT_MULTI_PLOT_VIEWER_TYPE} from '../visualizations/viewers/line-plot-viewer/line-plot-viewer';
import {SiteHooks, IEditChannelsChannelMetadata, EditChannelsChannelMetadata} from '../visualizations/site-hooks';
import {StudyJob} from '../visualizations/study-job';
import {SourceMetadata, SourceLoaderBase} from '../visualizations/viewers/channel-data-loaders/source-loader';
import {ViewerChannelData} from '../visualizations/viewers/channel-data-loaders/viewer-channel-data';
import {SourceLoaderViewModel} from '../visualizations/viewers/channel-data-loaders/source-loader-set';
import {RequestedLayoutIds} from '../visualizations/site-hooks';
import {Dayjs as DayjsService} from '../common/dayjs.service';
import {Dayjs} from 'dayjs';
import {Timer} from '../common/timer.service';
import {
  OutOfZoneFileLoader,
  OutOfZoneNavigationStation,
  OutOfZoneSiteHooks
} from '../simulations/visualizations/visualization-zones';

@Component({
  selector: 'cs-tenant-study-statistics',
  templateUrl: './tenant-study-statistics.component.html',
  styleUrls: ['./tenant-study-statistics.component.scss']
})
export class TenantStudyStatisticsComponent implements OnInit, OnDestroy {
  @Input() public tenantId: string;

  public errorMessage: string;
  public isAdministrator: boolean;

  public statistics: ProcessedStatistics;

  public navigationStation: OutOfZoneNavigationStation;

  private fileLoader: CanopyFileLoader;
  private siteHooks: CanopySiteHooks;
  private usernameMap: UsernameMap;

  private startOfStatistics: Dayjs;
  private endOfStatistics: Dayjs;
  private existingStatistics: any = {};

  public canLoadMore: boolean = false;

  constructor(
    private readonly studyStub: StudyStub,
    private readonly getUsernameMap: GetUsernameMap,
    private readonly utilities: TenantStudyStatisticsUtilities,
    private readonly dayjs: DayjsService,
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly canopySiteHooksFactory: CanopySiteHooksFactory,
    private readonly timer: Timer,
    private readonly zone: NgZone,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public ngOnDestroy() {
    if(this.navigationStation){
      this.navigationStation.dispose();
    }
  }

  public async load() {
    try {
      this.usernameMap = await this.getUsernameMap.execute(this.tenantId);
      this.fileLoader = this.canopyFileLoaderFactory.create();
      this.siteHooks = this.canopySiteHooksFactory.create();

      await this.loadNext();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadNext() {
    try {
      this.canLoadMore = false;
      this.statistics = undefined;
      if(this.navigationStation){
        this.navigationStation.dispose();
      }

      if(!this.startOfStatistics){
        let startOfMonth = this.dayjs.create().utc().startOf('month');
        this.startOfStatistics = startOfMonth.subtract(3, 'month');
      } else{
        this.endOfStatistics = this.startOfStatistics.clone();
        this.startOfStatistics = this.startOfStatistics.subtract(3, 'month');
      }

      let statisticsResult = await this.studyStub.getTenantStudyStatistics(
        this.tenantId,
        this.startOfStatistics.format(),
        this.endOfStatistics ? this.endOfStatistics.format() : undefined);

      this.existingStatistics = this.utilities.mergeRawStatistics(this.existingStatistics, statisticsResult.statistics);

      this.statistics = this.processStatistics(this.existingStatistics, this.usernameMap);

      let usernames = this.utilities.getStringKeys(this.statistics.monthly.user);
      let studyTypes = this.utilities.getStringKeys(this.statistics.monthly.studyType);

      await this.timer.yield();

      this.navigationStation = new OutOfZoneNavigationStation(this.zone, new NavigationStation(
        'navigation-station',
        new TenantStudyStatisticsConfigBuilder(
          this.statistics,
          usernames,
          studyTypes,
          new OutOfZoneFileLoader(this.zone, this.fileLoader),
          new OutOfZoneSiteHooks(this.zone, this.siteHooks),
          [{ studyId: 'dummy-study', jobIndex: 0 }],
          ['dummy-sim-type'])));

      await this.navigationStation.build();

      await this.timer.delay(5000);
      this.canLoadMore = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public processStatistics(statistics: RawStatistics, usernameMap: UsernameMap): ProcessedStatistics {
    let dateStrings = this.getDateStrings(statistics);
    let monthly = this.utilities.getProcessedStatistics(statistics, dateStrings.months, 'monthly', usernameMap);
    let daily = this.utilities.getProcessedStatistics(statistics, dateStrings.days, 'daily', usernameMap);
    return {
      monthly,
      daily
    };
  }

  public getDateStrings(statistics: RawStatistics): { months: string[]; days: string[] } {
    let ranges = this.utilities.getDateRanges(statistics);

    return {
      months: this.utilities.getDatesForRange(ranges.minMonth, ranges.maxMonth, 'YYYY-MM', 'M'),
      days: this.utilities.getDatesForRange(ranges.minDay, ranges.maxDay, 'YYYY-MM-DD', 'd'),
    };
  }
}


class TenantStudyStatisticsConfigBuilder extends ConfigBuilderBase {
  constructor(
    private statistics: ProcessedStatistics,
    private usernames: string[],
    private studyTypes: string[],
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[]){
    super(urlFileLoader, siteHooks, studyJobs, simTypes);
  }

  public async build(): Promise<NavigationStationConfig> {
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    await this.createView(config, 'Monthly Failed Simulations', 'monthly', 'failedSimulations');
    await this.createView(config, 'Monthly Successful Storage Credits', 'monthly', 'succeededStorageCredits');
    await this.createView(config, 'Monthly Successful Compute Credits', 'monthly', 'succeededComputeCredits');
    await this.createView(config, 'Monthly Successful Simulations', 'monthly', 'succeededSimulations');
    await this.createView(config, 'Monthly Simulations', 'monthly', 'completedSimulations');
    await this.createView(config, 'Monthly Studies', 'monthly', 'studyCount');
    await this.createView(config, 'Daily Failed Simulations', 'daily', 'failedSimulations');
    await this.createView(config, 'Daily Successful Storage Credits', 'daily', 'succeededStorageCredits');
    await this.createView(config, 'Daily Successful Compute Credits', 'daily', 'succeededComputeCredits');
    await this.createView(config, 'Daily Successful Simulations', 'daily', 'succeededSimulations');
    await this.createView(config, 'Daily Simulations', 'daily', 'completedSimulations');
    await this.createView(config, 'Daily Studies', 'daily', 'studyCount');

    return Promise.resolve(config);
  }

  private async createView(config: NavigationStationConfig, title: string, period: keyof ProcessedStatistics, property: keyof RawStatisticsPeriodItem): Promise<void> {
    let sharedState = new SharedState();
    sharedState.sourceLoaderSet.add(
      new SourceLoaderViewModel(
        new StatisticsSourceLoader(period, property, this.statistics, this.usernames, this.studyTypes)));
    config.sharedStates.push(sharedState);

    let xDomainName = period === 'daily' ? 'Day' : 'Month';

    let layoutId = `Default-TenantStatistics-${property}-${xDomainName}`;
    let layout = await this.resolveViewerLayout(
      POINT_MULTI_PLOT_VIEWER_TYPE,
      new RequestedLayoutIds(layoutId),
      {
        overrideLineCurve: 'curveStepBefore',
        columns: [
          {
            channels: [xDomainName],
          }
        ],
        rows: [
          {
            channels: ['Total'],
            relativeSize: 1
          },
          {
            channels: this.usernames,
            relativeSize: 2
          },
          {
            channels: this.studyTypes,
            relativeSize: 2
          },
        ]
      },
      true);


    config.views.push({
      title,
      viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
      layout,
      viewer: LinePlotViewer.createLinePlotViewer(
        xDomainName,
        layout.resolvedLayout.getConfigCopy(),
        config.channelNameStyle,
        sharedState,
        this.siteHooks),
      grid: {
        x: 0,
        y: 0,
        width: 12,
        height: 10
      }
    });
  }
}

class StatisticsSourceLoader extends SourceLoaderBase {
  constructor(private period: keyof ProcessedStatistics,
              private property: keyof RawStatisticsPeriodItem,
              private statistics: ProcessedStatistics,
              private usernames: string[],
              private studyTypes: string[]) {
    super();
  }

  getSourceMetadata(): Promise<SourceMetadata> {
    return Promise.resolve(new SourceMetadata('Tenant Statistics'));
  }

  getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<ViewerChannelData> {
    let simTypeStatistics = this.statistics[this.period];
    let data: number[];
    if (requestedName === 'Day' || requestedName === 'Month') {
      let lastIndex = simTypeStatistics.dates.length - 1;
      data = simTypeStatistics.dates.map((v, i) => i - lastIndex);
    } else if (requestedName === 'Total') {
      data = simTypeStatistics.tenant.map(v => v[this.property]);
    } else if (this.usernames.indexOf(requestedName) !== -1) {
      data = simTypeStatistics.user[requestedName].map(v => v[this.property]);
    } else {
      data = simTypeStatistics.studyType[requestedName].map(v => v[this.property]);
    }

    let result = new ViewerChannelData(
      requestedName,
      requestedName,
      requestedName,
      requestedName,
      '',
      '',
      false,
      undefined,
      data);

    return Promise.resolve(result);
  }

  getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IEditChannelsChannelMetadata[]> {
    return Promise.resolve([
      new EditChannelsChannelMetadata('Day', ''),
      new EditChannelsChannelMetadata('Month', ''),
      new EditChannelsChannelMetadata('Total', ''),
      ...this.usernames.map(v => new EditChannelsChannelMetadata(v, '')),
      ...this.studyTypes.map(v => new EditChannelsChannelMetadata(v, '')),
    ]);
  }
}
