import {Component} from '@angular/core';
import { CHART_SHORTCUT_KEYS_URL } from '../../common/constants';

/**
 * Component for displaying the chart help dialog.
 */
@Component({
  selector: 'cs-chart-help',
  templateUrl: './chart-help.component.html',
  styleUrls: ['./chart-help.component.scss']
})
export class ChartHelpComponent {

  /**
   * The link to the article about chart shortcut keys.
   */
  public CHART_SHORTCUT_KEYS_URL: string = CHART_SHORTCUT_KEYS_URL;

  /**
   * Whether to show the help dialog.
   */
  public showHelp: boolean;

  /**
   * Toggle the chart help visibility.
   */
  public toggleHelp(){
    this.showHelp = !this.showHelp;
  }
}
