@if (hasLabels) {
  @if (isNumericStatistics) {
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Min</th>
          <th>Mean</th>
          <th>Max</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        @for (label of labels; track label) {
          <tr>
            <td class="label-name">{{label.name}}</td>
            <td>
              <cs-unit
                [simpleRender]="true"
                [channelName]="label.name"
                [units]="label.units"
                [value]="label.numericStatistics.minimum"
              [significantFigures]="4"></cs-unit>
            </td>
            <td>
              <cs-unit
                [simpleRender]="true"
                [channelName]="label.name"
                [units]="label.units"
                [value]="label.numericStatistics.mean"
              [significantFigures]="4"></cs-unit>
            </td>
            <td>
              <cs-unit
                [simpleRender]="true"
                [channelName]="label.name"
                [units]="label.units"
                [value]="label.numericStatistics.maximum"
              [significantFigures]="4"></cs-unit>
            </td>
            <td>
              <cs-unit
                [simpleRender]="true"
                [channelName]="label.name"
                [units]="label.units"
              [showUnits]="true"></cs-unit>
            </td>
          </tr>
        }
      </tbody>
    </table>
  }
  @if (!isNumericStatistics) {
    <table>
      <tbody>
        @for (label of labels; track label; let idx = $index) {
          <tr>
            <td class="label-name">{{label.name | toShortPath:1}}</td>
            @if (isNumericValue(idx)) {
              <td>
                <cs-unit
                  [simpleRender]="true"
                  [channelName]="label.name | toShortPath:1"
                  [units]="label.units"
                  [value]="label.numericValue"
                [significantFigures]="4"></cs-unit>
                <cs-unit
                  [simpleRender]="true"
                  [channelName]="label.name | toShortPath:1"
                  [units]="label.units"
                [showUnits]="true"></cs-unit>
              </td>
            }
            @if (isStringValue(idx)) {
              <td>
                {{label.stringValue}}
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  }
}
