import {Injectable} from '@angular/core';
import {ResultSource, ResultsState} from '../results-staging-area/results-staging-area.service';
import {
  StudyStub, GetStudyQueryResult, SimType,
  StudyJobState
} from '../../../generated/api-stubs';
import {StudySource, StudySourceJob} from './visualization-factory.service';
import {POST_PROCESSOR_JOB_NAME} from '../jobs/view-job/view-job.component';
import {AccessInformation} from './retrying-file-loader-base';

/**
 * A collection of utilities for getting study data required for visualizations.
 */
@Injectable()
export class VisualizationUtilities {

  /**
   * Creates a new instance of the VisualizationUtilities class.
   * @param studyStub The study stub.
   */
  constructor(
    private studyStub: StudyStub){
  }

  /**
   * Gets the study sources and access signatures based on an item in the results staging area.
   * @param resultsState The results state.
   * @param canVisualizeStudy A function that determines if a study can be visualized.
   * @returns A promise that resolves to the study sources and access signatures.
   */
  public async getStudySourcesAndAccessSignature(resultsState: ResultsState, canVisualizeStudy: CanVisualizeStudyFunction): Promise<StudySourcesAndAccessSignature> {

    if(!resultsState.sources.length){
      return undefined;
    }

    let results: StudySource[] = [];

    for (let source of resultsState.sources) {
      let studyMetadataResult = await this.studyStub.getStudyMetadata(source.tenantId, source.studyId);

      if (!canVisualizeStudy(studyMetadataResult)) {
        continue;
      }

      let studySources = await this.getStudySources(
        source,
        studyMetadataResult.accessInformation,
        studyMetadataResult.simTypes);

      if(studySources){
        results.push(studySources);
      }
    }

    return new StudySourcesAndAccessSignature(results);
  }

  /**
   * Gets the study sources based on an item in the results staging area.
   * @param source The result source.
   * @param accessInformation The access information.
   * @param studySimTypes The study simulation types.
   * @returns A promise that resolves to the study sources.
   */
  public async getStudySources(source: ResultSource, accessInformation: AccessInformation, studySimTypes: SimType[]): Promise<StudySource>{
    let result: StudySource;

    if (source.jobId) {
      let jobMetadata = await this.studyStub.getStudyJobMetadata(source.tenantId, source.studyId, source.jobId);
      if (jobMetadata.studyJob.data.state === StudyJobState.successful) {
        result = new StudySource(source.tenantId, source.studyId, source.studyName, [new StudySourceJob(source.jobId, source.jobName)], studySimTypes, accessInformation);
      }
    } else {
      let jobs = await this.studyStub.getStudyJobs(source.tenantId, source.studyId);
      let jobIds: StudySourceJob[] = [];
      for (let job of jobs.queryResults.documents) {
        if (job.data.state === StudyJobState.successful && job.name !== POST_PROCESSOR_JOB_NAME) {
          jobIds.push(new StudySourceJob(job.documentId, job.name));
        }
      }
      if(jobIds.length){
        result = new StudySource(source.tenantId, source.studyId, source.studyName, jobIds, studySimTypes, accessInformation);
      }
    }

    return result;
  }
}

/**
 * A class that represents a set of study sources and access signatures.
 */
export class StudySourcesAndAccessSignature{

  /**
   * Creates a new instance of the StudySourcesAndAccessSignature class.
   * @param studySources The study sources (this structure also contains the access signatures).
   */
  constructor(
    public studySources: StudySource[]
  ){}
}

/**
 * A function that determines if a study can be visualized.
 */
export type CanVisualizeStudyFunction = (studyMetadata: GetStudyQueryResult) => boolean;
