import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {IEditChannelsOptions} from '../../visualizations/site-hooks';

/**
 * The channel editor dialog service.
 */
@Injectable()
export class ChannelEditorDialog {

  /**
   * Creates a new channel editor dialog service.
   * @param dialogManager The dialog manager.
   */
  constructor(
    private dialogManager: DialogManager
  ){}

  /**
   * Displays the channel editor dialog and returns the result of the dialog.
   * @param options The options for editing the chart.
   * @returns The result of the dialog.
   */
  public async editChart(
    options: IEditChannelsOptions): Promise<any> {

    let result = await this.dialogManager.showSecondaryDialog(
      new ChannelEditorDialogData(options));

    return result;
  }
}

/**
 * The dialog data for the channel editor dialog.
 */
export class ChannelEditorDialogData extends DialogBase<any> implements IDialog {

  /**
   * Creates a new instance of the channel editor dialog data.
   * @param _options The options for editing the chart.
   */
  constructor(
    private _options: IEditChannelsOptions) {
    super();
  }

  /**
   * Gets the options for editing the chart.
   */
  public get options(): IEditChannelsOptions {
    return this._options;
  }
}
