import { Injectable, Directive } from '@angular/core';
import {LocalStorage} from '../../common/local-storage.service';
import {LocalStateService} from '../local-state-service';
import {ServiceOnInit} from '../../common/service-on-init';
import {getJobIndexFromJobId} from '../../common/get-job-index-from-job-id';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const LOCAL_STORAGE_KEY = 'resultsStagingArea';

@Directive()
@Injectable()
export class ResultsStagingArea extends LocalStateService<ResultsState, IMutableResultsState> implements ServiceOnInit {

  constructor(
    authenticationService: AuthenticationService,
    localStorage: LocalStorage){
    super(authenticationService, localStorage);
  }

  protected fromMutable(state: IMutableResultsState): ResultsState {
    return ResultsState.fromMutable(state);
  }

  protected toMutable(state: ResultsState): IMutableResultsState {
    return ResultsState.toMutable(state);
  }

  protected uninitialized(userId: string): ResultsState {
    return ResultsState.uninitialized(userId);
  }

  protected getLocalStorageKey(): string {
    return LOCAL_STORAGE_KEY;
  }

  public addSource(source: ResultSource){
    this.removeSource(source.studyId, source.jobId);

    let mutable = ResultsState.toMutable(this.state);
    mutable.sources.push(ResultSource.toMutable(source));
    this.update(mutable);
  }

  public moveSourceLeft(studyId: string, jobId?: string){
    let mutable = ResultsState.toMutable(this.state);

    let index = mutable.sources.findIndex(
      source => source.studyId === studyId && source.jobId === jobId);

    if(index === -1){
      return;
    }

    if(index === 0){
      return;
    }

    let temp = mutable.sources[index];
    mutable.sources[index] = mutable.sources[index - 1];
    mutable.sources[index - 1] = temp;

    this.update(mutable);
  }

  public moveSourceRight(studyId: string, jobId?: string){
    let mutable = ResultsState.toMutable(this.state);

    let index = mutable.sources.findIndex(
      source => source.studyId === studyId && source.jobId === jobId);

    if(index === -1){
      return;
    }

    if(index === mutable.sources.length - 1){
      return;
    }

    let temp = mutable.sources[index];
    mutable.sources[index] = mutable.sources[index + 1];
    mutable.sources[index + 1] = temp;

    this.update(mutable);
  }

  public removeSource(studyId: string, jobId?: string){
    let mutable = ResultsState.toMutable(this.state);

    mutable.sources = mutable.sources.filter(
      source => !(source.studyId === studyId && source.jobId === jobId));

    this.update(mutable);
  }

  public serviceOnInit() {
    super.initialize();
  }
}

export class ResultsState {
  public static uninitialized(userId: string) {
    return new ResultsState(userId, []);
  }

  constructor(
    public readonly userId: string,
    public readonly sources: ReadonlyArray<ResultSource>) {
    this.sources = [...(this.sources || [])];
  }

  public static fromMutable(mutable: IMutableResultsState) {
    if(!mutable){
      return undefined;
    }

    return new ResultsState(
      mutable.userId,
      mutable.sources.map(ResultSource.fromMutable));
  }

  public static toMutable(value: ResultsState): IMutableResultsState {
    if(!value){
      return undefined;
    }

    return {
      userId: value.userId,
      sources: value.sources.map(ResultSource.toMutable)};
  }
}

export class ResultSource {
  constructor(
    public readonly tenantId: string,
    public readonly studyId: string,
    public readonly studyName: string,
    public readonly jobId: string,
    public readonly jobName: string) {
  }

  public get jobIndex() {
    return getJobIndexFromJobId(this.jobId);
  }

  public static fromMutable(mutable: IMutableResultSource) {
    if(!mutable){
      return undefined;
    }

    return new ResultSource(
      mutable.tenantId,
      mutable.studyId,
      mutable.studyName,
      mutable.jobId,
      mutable.jobName);
  }

  public static toMutable(value: ResultSource): IMutableResultSource {
    if(!value){
      return undefined;
    }

    return {
      tenantId: value.tenantId,
      studyId: value.studyId,
      studyName: value.studyName,
      jobId: value.jobId,
      jobName: value.jobName
    };
  }
}

export interface IMutableResultsState {
  userId: string;
  sources: IMutableResultSource[];
}

export interface IMutableResultSource {
  tenantId: string;
  studyId: string;
  studyName: string;
  jobId: string;
  jobName: string;
}
