import { ViewerWrapper } from './viewer-wrapper';
import { LoadViewerLayoutResult, RequestedLayoutIds } from '../site-hooks';
import { GridItemHTMLElement, GridStack } from 'gridstack';
import { ISize } from '../viewers/size';

/**
 * Wraps a viewer with a GridStack item, so it can be dragged, resized, etc.
 */
export class GridStackViewerWrapper implements ViewerWrapper {

  /**
   * Creates a new GridStackViewerWrapper.
   * @param inner The inner wrapper.
   * @param grid The GridStack instance.
   */
  constructor(
    private inner: ViewerWrapper,
    private grid: GridStack) {
  }

  /**
   * The GridItem element.
   */
  public node: GridItemHTMLElement;

  /**
   * Forwards on the reload request to the inner wrapper.
   * @returns A promise that resolves when the reload is complete.
   */
  public reload(): Promise<void> {
    return this.inner.reload();
  }

  /**
   * Forwards on the dispose request to the inner wrapper.
   */
  public dispose() {
    return this.inner.dispose();
  }

  /**
   * Forwards on the setDefaultChartUpdated request to the inner wrapper.
   * @param viewerType The viewer type.
   * @param resultOriginalViewerId The original viewer id.
   * @param result The result.
   * @returns A promise that resolves when the default chart is updated.
   */
  public setDefaultChartUpdated(viewerType: string, resultOriginalViewerId: RequestedLayoutIds, result: LoadViewerLayoutResult): Promise<void> {
    return this.inner.setDefaultChartUpdated(viewerType, resultOriginalViewerId, result);
  }

  /**
   * Initializes the GridStack item and forwards on the initialize request to the inner wrapper.
   * @param elementId The element id.
   * @param viewIndex The view index.
   * @param viewConfig The view config.
   */
  public async initialize(elementId: string, viewIndex: number, viewConfig: any): Promise<void> {
    const innerElementId = elementId + '-' + viewIndex;

    this.node = this.grid.addWidget(
    {
      w: viewConfig.grid.width,
      h: viewConfig.grid.height,
      id: innerElementId,
      content: `
      <div class="card viewer-${viewConfig.viewerType}">
        <div class="card-header cs-grid-stack-drag-area">
          <h3 id="${innerElementId + '-viewer-title'}" class="viewer-title">
            ${viewConfig.title}
            <small class="fw-lighter" id="${innerElementId + '-sub-title'}"></small>
          </h3>
          <div class="move-indicator"><i class="fa fa-arrows"></i></div>
        </div>
        <div id="${innerElementId + '-card'}" class="card-block">
        </div>
      </div>`
    });

    await this.inner.initialize(innerElementId, viewIndex, viewConfig);
  }

  /**
   * Forwards on the setSizeUpdated request to the inner wrapper.
   * @param size The size.
   * @returns A promise that resolves when the size is updated.
   */
  public setSizeUpdated(size: ISize): Promise<void> {
    return this.inner.setSizeUpdated(size);
  }

  /**
   * Forwards on the build request to the inner wrapper.
   * @param elementId The element id.
   * @param viewIndex The view index.
   * @param viewConfig  The view config.
   */
  public async build(elementId: string, viewIndex: number, viewConfig: any): Promise<void> {
    const innerElementId = elementId + '-' + viewIndex;
    await this.inner.build(innerElementId, viewIndex, viewConfig);
  }

  /*
    public async build(elementId: string, viewIndex: number, viewConfig: any):Promise<void> {
      var root = $('#' + elementId);
      var innerElementId = elementId + '-' + viewIndex;

      root.append('<div class="row" id="row' + viewIndex + '"></div>');
      $('#row' + viewIndex, root).append('<div class="col-sm-6">' + this.makeChartCardHtml(innerElementId, viewConfig.title) + '</div>');

      await this.inner.build(innerElementId, viewIndex, viewConfig);
    }

    // make some html for a card with a header and a particular id
    private makeChartCardHtml(innerElementId: string, title: string) {
      return '<div class="card"> <h4 class="card-header"> ' + title + '</h4> <div class="card-block"> <div id="' + innerElementId + '"> </div> </div> </div>';
      // return '<div class="card"> <h3 class="card-header"> ' + title + '</h3> <div class="card-block"> <div id="chartCard' + index + '"> </div> </div> </div> </div>';
    }
  */
}
