import { HTMLDivSelection } from '../untyped-selection';
import * as d3 from '../d3-bundle';
import { BootstrapBase } from './bootstrap-base';
import { BootstrapSliders } from './components/sliders.bootstrap';
import { BootstrapSourceLabels } from './components/source-labels-renderer.bootstrap';
import { getLoadButtonId } from './get-load-button-id';
import { getRetinaRatio } from '../viewers/chart-settings';
import { CURRENT_PIXEL_RATIO_ID, TEST_AREA_END_ID, TEST_AREA_TITLE_CLASS } from './constants';
import { Disposable } from '../disposable';
import { BootstrapLegendRenderer } from './components/legend-renderer.bootstrap';
import { BootstrapMultiPlotAxisRenderer } from './components/axis-renderer.bootstrap';
import { BootstrapSingleJobMultiLapOverlaySplitLaps } from './charts/single-job-multi-lap-overlay-split-laps.bootstrap';
import { BootstrapMultiJobOverlayHideJobs } from './charts/multi-job-overlay-hide-jobs.bootstrap';
import { BootstrapStarContainingNaNScalarResultsInAllLaps } from './charts/star-containing-nan-scalar-results-in-all-laps.bootstrap';
import { BootstrapFailedFirstJob } from './charts/failed-first-job.bootstrap';
import { BootstrapFactorialComponentEnumeration } from './charts/factorial-component-enumeration.bootstrap';
import { Injectable } from '@angular/core';
import { CanopyFileLoaderFactory } from '../../simulations/visualizations/canopy-file-loader.service';
import { CanopySiteHooksFactory } from '../../simulations/visualizations/canopy-site-hooks.service';
import { AuthenticationService } from '../../identity/state/authentication.service';
// import { BootstrapMonteCarloNoApexSim } from '../test/e2e/app/navigation-station/monte-carlo-no-apex-sim.bootstrap';
// import { BootstrapMonteCarlo8D } from '../test/e2e/app/navigation-station/monte-carlo-8d.bootstrap';
// import { BootstrapScatterPlotViewer } from '../test/e2e/app/scatter-plot-viewer/scatter-plot-viewer.bootstrap';
// import { BootstrapScatterPlotDataRenderer } from '../test/e2e/app/scatter-plot-viewer/data-renderer.bootstrap';
// import { BootstrapLinePlotViewer } from '../test/e2e/app/line-plot-viewer/line-plot-viewer.bootstrap';
// import { BootstrapMultiJobMultiSimOverlay } from '../test/e2e/app/navigation-station/multi-job-multi-sim-overlay.bootstrap';
// import { BootstrapTwoLapMonteCarlo } from '../test/e2e/app/navigation-station/two-lap-monte-carlo.bootstrap';
// import { BootstrapSingleJobMultiSim } from '../test/e2e/app/navigation-station/single-job-multi-sim.bootstrap';
// import { BootstrapCar } from '../test/e2e/app/navigation-station/car.bootstrap';
// import { BootstrapMultiJobMultiLapOverlay } from '../test/e2e/app/navigation-station/multi-job-multi-lap-overlay.bootstrap';
// import { BootstrapElevationStudy } from '../test/e2e/app/navigation-station/elevation-study.bootstrap';
// import { BootstrapCarPreview } from '../test/e2e/app/navigation-station/car-preview.bootstrap';
// import { BootstrapRacingLineJob } from '../test/e2e/app/navigation-station/racing-line-job.bootstrap';
// import { BootstrapTrackPreviewCamber } from '../test/e2e/app/navigation-station/track-preview-camber.bootstrap';
// import { BootstrapTrackPreview } from '../test/e2e/app/navigation-station/track-preview.bootstrap';
// import { BootstrapCarWithoutSuspensionPreview } from '../test/e2e/app/navigation-station/car-without-suspension-preview.bootstrap';
// import { BootstrapTrackNonPeriodicPreview } from '../test/e2e/app/navigation-station/track-non-periodic.bootstrap';
// import { BootstrapTrackDiagonalStartFinishLineAndOffset } from '../test/e2e/app/navigation-station/track-diagonal-start-finish-line-and-offset.bootstrap';
// import { BootstrapOvertakingLap } from '../test/e2e/app/navigation-station/overtaking-lap.bootstrap';
// import { TelemetryOverlay } from '../test/e2e/app/navigation-station/telemetry-overlay.bootstrap';
// import { BootstrapChannelInferenceOverlay } from '../test/e2e/app/navigation-station/channel-inference-overlay.bootstrap';
// import { BootstrapTelemetryPreview } from '../test/e2e/app/navigation-station/telemetry-preview.bootstrap';
// import { BootstrapRacingLineWithMetadataJob } from '../test/e2e/app/navigation-station/racing-line-with-metadata-job.bootstrap';
// import { BootstrapTrackWidthAdjustAffectsElevationRendering } from '../test/e2e/app/navigation-station/track-width-adjust-affects-elevation-rendering.bootstrap';
// import { BootstrapFactorialReversedInputsWithEnumeration } from '../test/e2e/app/navigation-station/factorial-reversed-inputs-with-enumeration.bootstrap';
// import { BootstrapMonteCarloReversedInputsPartialCoverage } from '../test/e2e/app/navigation-station/monte-carlo-reversed-inputs-partial-coverage.bootstrap';
// import { BootstrapFactorialReversedInputsWithConfigEnumeration } from '../test/e2e/app/navigation-station/factorial-reversed-inputs-with-config-enumeration.bootstrap';
// import { BootstrapFactorialReversedArrayInput } from '../test/e2e/app/navigation-station/factorial-reversed-array-input.bootstrap';
// import { BootstrapTrackRacingLineDefinedByXYPreview } from '../test/e2e/app/navigation-station/track-racing-line-defined-by-xy';
// import { BootstrapTrackRacingLineDefinedBygLatPreview } from '../test/e2e/app/navigation-station/track-racing-line-defined-by-gLat';
// import { BootstrapTrackChallengingJob } from '../test/e2e/app/navigation-station/track-challenging-job.bootstrap';
// import { BootstrapTrackDmlMoreThanMaximumVertices } from '../test/e2e/app/navigation-station/track-dml-more-than-maximum-vertices.bootstrap';
// import { BootstrapSLapErrorAccumulation } from '../test/e2e/app/navigation-station/slap-error-accumulation.bootstrap';
// import { BootstrapTrackConverter } from '../test/e2e/app/navigation-station/track-converter.bootstrap';
// import { BootstrapTrackPreviewDaytona } from '../test/e2e/app/navigation-station/track-daytona';
// import { BootstrapCarWithEmbeddedCustomProperties } from '../test/e2e/app/navigation-station/car-with-embedded-custom-properties.bootstrap';
// import { BootstrapUserMathsNonSiUnits } from '../test/e2e/app/navigation-station/user-maths-non-si-units.bootstrap';
// import { BootstrapAscendingDescendingDimensions } from '../test/e2e/app/navigation-station/factorial-ascending-descending-dimensions.bootstrap';
// import { BootstrapStarSingleElementDimension } from '../test/e2e/app/navigation-station/star-single-element-dimension.bootstrap';
// import { BootstrapFactorialMonteCarlo } from '../test/e2e/app/navigation-station/factorial-monte-carlo.bootstrap';
// import { BootstrapStarSingleArrayElementDimension } from '../test/e2e/app/navigation-station/star-single-array-element-dimension.bootstrap';

const BOOTSTRAP_DELEGATES: BootstrapBase[] = [
  new BootstrapSliders(),
  new BootstrapSourceLabels(),
  new BootstrapLegendRenderer(),
  new BootstrapMultiPlotAxisRenderer(),
  new BootstrapSingleJobMultiLapOverlaySplitLaps(),
  new BootstrapMultiJobOverlayHideJobs(),
  new BootstrapStarContainingNaNScalarResultsInAllLaps(),
  new BootstrapFailedFirstJob(),
  new BootstrapFactorialComponentEnumeration(),
  // //new BootstrapLineRenderer(),
  // new BootstrapSingleJobMultiSim(),
  // new BootstrapMultiJobMultiSimOverlay(),
  // new BootstrapMultiJobMultiLapOverlay(),
  // new TelemetryOverlay(),
  // new BootstrapChannelInferenceOverlay(),
  // new BootstrapMonteCarlo8D(),
  // new BootstrapMonteCarloNoApexSim(),
  // new BootstrapTwoLapMonteCarlo(),
  // new BootstrapScatterPlotDataRenderer(),
  // new BootstrapScatterPlotViewer(),
  // new BootstrapLinePlotViewer(),
  // new BootstrapCar('asymmetric'),
  // new BootstrapCar('motor-loss-maps'),
  // new BootstrapCar('motor-efficiency-maps'),
  // new BootstrapElevationStudy(),
  // new BootstrapCarPreview(),
  // new BootstrapCarWithoutSuspensionPreview(),
  // new BootstrapTrackPreview(),
  // new BootstrapTrackPreviewCamber(),
  // new BootstrapTrackNonPeriodicPreview(),
  // new BootstrapTrackDiagonalStartFinishLineAndOffset(),
  // new BootstrapTrackWidthAdjustAffectsElevationRendering(),
  // new BootstrapTrackRacingLineDefinedByXYPreview(),
  // new BootstrapTrackRacingLineDefinedBygLatPreview(),
  // new BootstrapRacingLineJob(),
  // new BootstrapRacingLineWithMetadataJob(),
  // new BootstrapOvertakingLap(),
  // new BootstrapTelemetryPreview(),
  // new BootstrapMonteCarloReversedInputsPartialCoverage(),
  // new BootstrapFactorialReversedInputsWithEnumeration(),
  // new BootstrapFactorialReversedInputsWithConfigEnumeration(),
  // new BootstrapFactorialReversedArrayInput(),
  // new BootstrapTrackChallengingJob(),
  // new BootstrapTrackDmlMoreThanMaximumVertices(),
  // new BootstrapSLapErrorAccumulation(),
  // new BootstrapTrackConverter(),
  // new BootstrapTrackPreviewDaytona(),
  // new BootstrapCarWithEmbeddedCustomProperties(),
  // new BootstrapUserMathsNonSiUnits(),
  // new BootstrapAscendingDescendingDimensions(),
  // new BootstrapStarSingleElementDimension(),
  // new BootstrapFactorialMonteCarlo(),
  // new BootstrapStarSingleArrayElementDimension(),
];

@Injectable()
export class BootstrapAutomatedTests {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly fileLoaderFactory: CanopyFileLoaderFactory,
    private readonly siteHooksFactory: CanopySiteHooksFactory) {
  }

  // Builds the automated tests pages. We are not using angular here because all the visualization code used
  // to be in a non-angular repository, and this was ported across.
  public async buildPage() {
    if (!document.getElementById('automated-tests')) {
      return;
    }

    const userData = this.authenticationService.userDataSnapshot;
    const fileLoader = this.fileLoaderFactory.create();
    const siteHooks = this.siteHooksFactory.create();

    let testAreaButtons = d3.selectAll<HTMLDivElement, unknown>('#automated-test-buttons');
    let testAreaStage = d3.selectAll<HTMLDivElement, unknown>('#automated-test-stage');
    let disposables: Disposable[] = [];

    for (let item of BOOTSTRAP_DELEGATES) {
      testAreaButtons.append('button')
        .on('click', async () => {
          for (let d of disposables) {
            d.dispose();
          }
          disposables = [];

          testAreaStage.selectAll('*').remove();

          this.createTitleElement(testAreaStage, item.name);

          let disposable = await item.load(testAreaStage, userData, fileLoader, siteHooks);
          if (disposable) {
            disposables.push(disposable);
          }

          this.createFinalElement(testAreaStage);
        })
        .attr('class', 'btn btn-secondary')
        .attr('id', getLoadButtonId(item.name))
        .text(item.name);

      if (item.loadImmediately) {
        this.createTitleElement(testAreaStage, item.name);
        let disposable = await item.load(testAreaStage, userData, fileLoader, siteHooks);
        if (disposable) {
          disposables.push(disposable);
        }
      }
    }

    this.createFinalElement(testAreaStage);
  }

  private createTitleElement(testArea: HTMLDivSelection, name: string) {
    testArea.append('h3').attr('class', TEST_AREA_TITLE_CLASS).text(name);
  }
  private createFinalElement(testArea: HTMLDivSelection) {
    let pixelRatioArea = testArea.append('p').attr('id', 'pixel-ratio-container');
    pixelRatioArea.append('span').text('Pixel Ratio: ');
    pixelRatioArea.append('span').attr('id', CURRENT_PIXEL_RATIO_ID).text(getRetinaRatio());
    testArea.append('h3').attr('id', TEST_AREA_END_ID).text('END');
  }
}
