import { ChannelInterpolator } from './channel-interpolator';
import { ChannelInterpolatorResults } from './channel-interpolator-results';
import { InterpolationCoordinates } from './interpolation-coordinates';
import { interpolateRange } from './interpolate-range';
import { ChannelInterpolatorExplorationBase } from './channel-interpolator-exploration-base';
import { RadialBasisFunction } from './radial-basis-function';
import { ChannelDataList } from './channel-data-list';
import { emptyValueInterpolatorResults, ValueInterpolatorResults } from './value-interpolator-results';
import { FilteredStudyExplorationAndScalarDataCache } from '../filtered-study-exploration-and-scalar-data-cache';

const MAX_RBF_TRAINING_STUDY_SIZE = 4000;

/**
 * An interpolator for Monte Carlo designs.
 */
export class ChannelInterpolatorMonteCarlo extends ChannelInterpolatorExplorationBase implements ChannelInterpolator {

  private allScalarDataList?: ChannelDataList;
  private rbf?: RadialBasisFunction;

  /**
   * Initializes a new instance of ChannelInterpolatorMonteCarlo.
   * @param explorationAndScalarDataCache The exploration and scalar data cache.
   */
  constructor(private readonly explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache) {
    super();
  }

  /**
   * @inheritdoc
   */
  public initialize() {
    // If the exploration or scalar data changes, retrain the RBF. This can happen if, for example, the
    // user removes points from the parallel coordinates plot.
    this.explorationAndScalarDataCache.changed.subscribe(() => this.onNeedTraining());
    this.onNeedTraining();
  }

  /**
   * Trains the RBF.
   */
  private onNeedTraining(){
    let explorationAndScalarData = this.explorationAndScalarDataCache.get();
    this.allScalarDataList = this.createScalarDataList(explorationAndScalarData.scalarData);

    // When there are lots of data, calculating the RBFs can take a long time and kill the
    // browser tab. The simplest thing for the moment is just to disable the RBF for really
    // large studies.
    if (this.allScalarDataList.dataLength > 0 && this.allScalarDataList.dataLength <= MAX_RBF_TRAINING_STUDY_SIZE) {
      this.rbf = new RadialBasisFunction(
        explorationAndScalarData.explorationMap.normalizedSweepIndices,
        this.allScalarDataList);

      this.rbf.train();
    }
  }

  /**
   * @inheritdoc
   */
  public execute(
    channelNames: ReadonlyArray<string>,
    interpolationCoordinates: InterpolationCoordinates): ChannelInterpolatorResults {

    // For each set of coordinates from interpolationCoordinates, call valueInterpolator.
    return interpolateRange(
      channelNames,
      (rPoint: number[]) => this.valueInterpolator(channelNames, rPoint),
      interpolationCoordinates);
  }

  /**
   * Interpolates the values for the given channels at the given interpolation coordinates using the trained RBF.
   * @param channelNames The names of the channels to interpolate values for.
   * @param rPoint The interpolation coordinates.
   * @returns The interpolated values for each channel at the given interpolation coordinates.
   */
  private valueInterpolator(channelNames: ReadonlyArray<string>, rPoint: number[]): ValueInterpolatorResults {
    let result: ValueInterpolatorResults = emptyValueInterpolatorResults(channelNames);
    if (this.rbf) {
      this.rbf.setCoordinates(rPoint);
      for (let channelName of channelNames) {
        result[channelName] = this.rbf.getChannelValue(channelName);
      }
    }
    return result;
  }
}
