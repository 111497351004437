import {CanopyFileLoader, CanopyFileLoaderFactory} from './canopy-file-loader.service';
import {NavigationStation, USE_FIRST_SUCCESSFUL_JOB_INDEX} from '../../visualizations/navigation-station/navigation-station';
import {StudyJob} from '../../visualizations/study-job';
import {CarConfigBuilder, Filter} from '../../visualizations/navigation-station/config-builders/car-config-builder';
import {MonteCarloConfigBuilder} from '../../visualizations/navigation-station/config-builders/monte-carlo-config-builder';
import {StarConfigBuilder} from '../../visualizations/navigation-station/config-builders/star-config-builder';
import {JobConfigBuilder} from '../../visualizations/navigation-station/config-builders/job-config-builder';
import {CanopySiteHooks, CanopySiteHooksFactory} from './canopy-site-hooks.service';
import {Injectable, NgZone} from '@angular/core';
import {
  SimType
} from '../../../generated/api-stubs';
import {getJobIndexFromJobId} from '../../common/get-job-index-from-job-id';
import {AccessInformation} from './retrying-file-loader-base';
import {JobViewModel} from '../jobs/job-results/job-view-model';
import {POST_PROCESSOR_JOB_NAME} from '../jobs/view-job/view-job.component';
import {TrackPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/track-preview-config-builder';
import {CarPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/car-preview-config-builder';
import {TelemetryPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/telemetry-preview-config-builder';
import {RacingLineJobConfigBuilder} from '../../visualizations/navigation-station/config-builders/racing-line-job-config-builder';
import {UnitsMap} from '../../visualizations/viewers/channel-data-loaders/local-config-source-loader';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {OutOfZoneFileLoader, OutOfZoneNavigationStation, OutOfZoneSiteHooks} from './visualization-zones';

/**
 * The job config data and optional view model.
 */
export class JobConfigData {

  /**
   * Creates a new instance of the JobConfigData class.
   * @param name The name.
   * @param data The data.
   * @param job The optional job view model.
   */
  constructor(
    public readonly name: string,
    public readonly data: any,
    public readonly job?: JobViewModel){
  }
}

/**
 * The visualization factory for creating navigation station instances which can be used to display visualizations.
 * Navigation station instances manage multiple viewers and their data, enabling things like synchronized cursors.
 */
@Injectable()
export class VisualizationFactory {

  /**
   * Creates a new instance of the VisualizationFactory class.
   * @param canopyFileLoaderFactory The canopy file loader factory.
   * @param canopySiteHooksFactory The canopy site hooks factory.
   * @param getSimVersion The get sim version service.
   * @param zone The Angular zone.
   */
  constructor(
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly canopySiteHooksFactory: CanopySiteHooksFactory,
    private readonly getSimVersion: GetSimVersion,
    private readonly zone: NgZone){
  }

  /**
   * Creates the caches for the visualizations which can be shared between visualizations so that they don't load the
   * same data multiple times.
   * @returns The visualization caches.
   */
  public createCaches(): VisualizationCaches {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create();

    return new VisualizationCaches(siteHooks, fileLoader);
  }

  /**
   * Creates a new instance of the navigation station for the cars.
   * @param elementId The element ID which should contain the viewers.
   * @param jobs The jobs to display data for.
   * @param filter The filter to apply.
   * @param caches The visualization caches.
   * @returns The navigation station.
   */
  public async createCarViewer(elementId: string, jobs: JobViewModel[], filter: Filter, caches: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new CarConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        [...viewerData.simTypes, 'ComponentSweeps'],
        filter,
        true)));
  }

  /**
   * Creates a new instance of the navigation station for a set of sim types.
   * @param elementId The element ID which should contain the viewers.
   * @param jobs The jobs to display data for.
   * @param simTypes The sim types to display.
   * @param caches The visualization caches.
   * @returns The navigation station.
   */
  public async createSimTypesViewer(elementId: string, jobs: JobViewModel[], simTypes: SimType[], caches: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new JobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        simTypes)));
  }

  /**
   * Creates a new instance of the navigation station for the racing line.
   * @param elementId The element ID which should contain the viewers.
   * @param jobs The jobs to display data for.
   * @param simTypes The sim types to display.
   * @param caches The visualization caches.
   * @param inputTrack The input track data.
   * @returns The navigation station.
   */
  public async createRacingLineViewer(elementId: string, jobs: JobViewModel[], simTypes: SimType[], caches: VisualizationCaches, inputTrack: any): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new RacingLineJobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        simTypes,
        inputTrack)));
  }

  /**
   * Creates a new instance of the navigation station for the track preview.
   * @param elementId The element ID which should contain the viewers.
   * @param configs The job config data.
   * @param unitsMap The units map.
   * @param caches The visualization caches.
   * @returns The navigation station.
   */
  public async createTrackPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create();

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new TrackPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        unitsMap)));
  }

  /**
   * Creates a new instance of the navigation station for the car preview.
   * @param elementId The element ID which should contain the viewers.
   * @param configs The job config data.
   * @param unitsMap The units map.
   * @param caches The visualization caches.
   * @returns The navigation station.
   */
  public async createCarPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create();

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new CarPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        unitsMap)));
  }

  /**
   * Creates a new instance of the navigation station for the telemetry preview.
   * @param elementId The element ID which should contain the viewers.
   * @param configs The job config data.
   * @param caches The visualization caches.
   * @returns The navigation station.
   */
  public async createTelemetryPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create();

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new TelemetryPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        8)));
  }

  /**
   * Populates the caches for the study jobs.
   * @param jobs The jobs to populate the caches for.
   * @param caches The visualization caches.
   * @returns The sim types and study jobs.
   */
  public populateCachesForStudyJobs(jobs: ReadonlyArray<JobViewModel>, caches: VisualizationCaches): Promise<{ simTypes: SimType[]; studyJobs: StudyJob[] }> {
    return this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
  }

  /**
   * Loads the metadata required to populate the caches, and populates the caches.
   * @param jobs The jobs to populate the viewer data for.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @returns The sim types and study jobs.
   */
  private async populateViewerData(jobs: ReadonlyArray<JobViewModel>, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): Promise<{ simTypes: SimType[]; studyJobs: StudyJob[] }> {
    let addedStudyIds: string[] = [];
    let studyJobs: StudyJob[] = [];
    let simTypes: SimType[] = [];

    // Job metadata ultimately comes from blob storage, so we won't be hammering DocumentDB.
    let jobMetadataTasks = jobs.map(v => v.jobName.tryLoad());
    await Promise.all(jobMetadataTasks);

    for (let job of jobs) {
      await job.studyMetadataResult.tryLoad();
      await job.studyName.tryLoad();
      await job.jobName.tryLoad();
      await job.simTypes.tryLoad();

      if (job.studyMetadataResult.value
        && job.jobName.value
        && job.studyName.value
        && job.jobName.value !== POST_PROCESSOR_JOB_NAME) {
        let tenantId = job.source.tenantId;
        let studyId = job.source.studyId;
        let jobId = job.source.jobId;
        let simVersion = job.simVersion;
        if (addedStudyIds.indexOf(studyId) === -1) {
          if(job.inlineStudyResult){
            fileLoader.addInlineStudy(tenantId, studyId, job.inlineStudyResult);
          } else{
            fileLoader.addStudy(tenantId, studyId, job.studyMetadataResult.value.accessInformation, simVersion);
            fileLoader.addStudyJob(tenantId, studyId, getJobIndexFromJobId(jobId), job);
          }
          siteHooks.addStudy(tenantId, studyId, job.studyName.value);
          addedStudyIds.push(studyId);
        }

        siteHooks.addJobName(jobId, job.jobName.value);
        studyJobs.push(new StudyJob(studyId, getJobIndexFromJobId(jobId)));

        if(job.simTypes.value){
          for(let simType of job.simTypes.value){
            if(simTypes.indexOf(simType) === -1){
              simTypes.push(simType);
            }
          }
        }
      }
    }

    return {
      simTypes,
      studyJobs
    };
  }

  /**
   * Creates a new instance of the navigation station for the job overlay viewer.
   * @param elementId The element ID which should contain the viewers.
   * @param studyUrls The study URLs.
   * @returns The navigation station.
   */
  public createJobOverlayViewer(elementId: string, studyUrls: StudySource[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create();

    for(let study of studyUrls){
      fileLoader.addStudy(study.tenantId, study.studyId, study.accessInformation, this.getSimVersion.currentSimVersion);
      siteHooks.addStudy(study.tenantId, study.studyId, study.name);
      for(let job of study.jobs){
        siteHooks.addJobName(job.jobId, job.name);
      }
    }

    let studyJobs = this.getStudyJobs(studyUrls);
    let simTypes = this.getAllSimTypes(studyUrls);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new JobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        studyJobs,
        simTypes)));
  }

  /**
   * Creates a new instance of the navigation station for the star exploration map viewer.
   * @param elementId The element ID which should contain the viewers.
   * @param accessInformation The access information.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param studyName The study name.
   * @param simTypes The sim types.
   * @returns The navigation station.
   */
  public createStarExplorationMapViewer(elementId: string, accessInformation: AccessInformation, tenantId: string, studyId: string, studyName: string, simTypes: SimType[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create();

    fileLoader.addStudy(tenantId, studyId, accessInformation, this.getSimVersion.currentSimVersion);
    siteHooks.addStudy(tenantId, studyId, studyName);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new StarConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        [{ studyId, jobIndex: USE_FIRST_SUCCESSFUL_JOB_INDEX }],
        [...simTypes, 'Debug'])));
  }

  /**
   * Creates a new instance of the navigation station for the parallel coordinates viewer (factorial and monte carlo explorations).
   * @param elementId The element ID which should contain the viewers.
   * @param accessInformation The access information.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param studyName The study name.
   * @param simTypes The sim types.
   * @returns The navigation station.
   */
  public createParallelCoordinatesViewer(elementId: string, accessInformation: AccessInformation, tenantId: string, studyId: string, studyName: string, simTypes: SimType[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create();

    fileLoader.addStudy(tenantId, studyId, accessInformation, this.getSimVersion.currentSimVersion);
    siteHooks.addStudy(tenantId, studyId, studyName);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new MonteCarloConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        [{ studyId, jobIndex: USE_FIRST_SUCCESSFUL_JOB_INDEX }],
        [...simTypes, 'Debug'])));
  }


  /**
   * Returns a list of study jobs (just the study ID and job index) for the given set of study sources.
   * @param studySources The study sources.
   * @returns The study jobs.
   */
  private getStudyJobs(studySources: StudySource[]): StudyJob[]{
    let result: StudyJob[] = [];
    for(let study of studySources){
      for(let job of study.jobs){
        let jobIndex = getJobIndexFromJobId(job.jobId);
        result.push(new StudyJob(study.studyId, jobIndex));
      }
    }

    return result;
  }

  /**
   * Returns a list of all sim types for the given set of study sources.
   * @param studySources The study sources.
   * @returns The sim types.
   */
  private getAllSimTypes(studySources: StudySource[]): SimType[]{
    let result: SimType[] = [];

    if(studySources.length === 0){
      return result;
    }

    for(let study of studySources){
      for(let simType of study.simTypes){
        if(result.indexOf(simType) === -1){
          result.push(simType);
        }
      }
    }

    return result;
  }
}

/**
 * A study source.
 */
export class StudySource {

  /**
   * Creates a new instance of the StudySource class.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param name The name.
   * @param jobs The jobs.
   * @param simTypes The sim types.
   * @param accessInformation The access information.
   */
  constructor(
    public tenantId: string,
    public studyId: string,
    public name: string,
    public jobs: StudySourceJob[],
    public simTypes: SimType[],
    public accessInformation: AccessInformation
  ){}
}

/**
 * A study source job.
 */
export class StudySourceJob {

  /**
   * Creates a new instance of the StudySourceJob class.
   * @param jobId The job ID.
   * @param name The name.
   */
  constructor(
    public jobId: string,
    public name: string){
  }
}

/**
 * The services which cache data used by the visualizations.
 * This can be shared between visualizations to avoid loading the same data multiple times.
 */
export class VisualizationCaches {

  /**
   * Creates a new instance of the VisualizationCaches class.
   * @param siteHooks The site hooks.
   * @param fileLoader The file loader.
   */
  constructor(
    public readonly siteHooks: CanopySiteHooks,
    public readonly fileLoader: CanopyFileLoader
  ){}
}
