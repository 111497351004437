import { UrlFileLoaderBase } from '../../visualizations/url-file-loader-base';
import { StudyStub } from '../../../generated/api-stubs';
import { getJobIdFromJobIndex } from '../../common/get-job-id-from-job-index';
import { HttpErrorResponse } from '@angular/common/http';
import { ChannelBinaryFormat } from '../../visualizations/url-file-loader';
import { Timer } from '../../common/timer.service';

/**
 * The number of times to retry.
 */
const MAX_ATTEMPTS: number = 5;

/**
 * Base class for file loaders that retry on errors, using a back-off strategy with MAX_ATTEMPTS retries.
 * The file loader also handles adding access signatures to the requests.
 * The retrying accounts for both transient errors, and 403 errors that require fetching new access signatures.
 * NOTE: Currently this does a retry irrespective of the error (except 404, which is handled in the base class).
 */
export abstract class RetryingFileLoaderBase extends UrlFileLoaderBase {

  /**
   * Creates a new instance of the RetryingFileLoaderBase class.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param studyStub The study stub.
   * @param timer The timer service.
   * @param accessInformation The access information.
   */
  constructor(
    protected readonly tenantId: string,
    protected readonly studyId: string,
    protected readonly studyStub: StudyStub,
    private readonly timer: Timer,
    private accessInformation: AccessInformation
  ) {
    super();
  }

  /**
   * Loads a text file from a URL.
   * @param url The URL of the text file.
   * @param jobIndex The optional job index.
   * @returns A promise that resolves to the text.
   */
  protected async loadText(url: string, jobIndex?: number): Promise<string> {
    return this.loadWithRetries(() => super.loadText(url + this.getAccessSignature(jobIndex)));
  }

  /**
   * Loads a JSON file from a URL.
   * @param url The URL of the JSON file.
   * @param jobIndex The optional job index.
   * @returns A promise that resolves to the JSON data.
   */
  protected async loadJson(url: string, jobIndex?: number): Promise<any> {
    return this.loadWithRetries(() => super.loadJson(url + this.getAccessSignature(jobIndex)));
  }

  /**
   * Loads a CSV file from a URL and returns the result where each row is
   * represented by an object.
   * @param url The URL of the CSV file.
   * @param jobIndex The optional job index.
   * @returns A promise that resolves to the CSV data.
   */
  public async loadCsv(url: string, jobIndex?: number): Promise<any[]> {
    return this.loadWithRetries(() => super.loadCsv(url + this.getAccessSignature(jobIndex)));
  }

  /**
   * Loads a CSV file from a URL and returns the result where each row is
   * represented by an array of strings.
   * @param url The URL of the CSV file.
   * @param jobIndex The optional job index.
   * @returns A promise that resolves to the CSV data.
   */
  public async loadCsvRows(url: string, jobIndex?: number): Promise<string[][]> {
    return this.loadWithRetries(() => super.loadCsvRows(url + this.getAccessSignature(jobIndex)));
  }

  /**
   * Loads a numeric array from a URL.
   * @param url The URL of the numeric array.
   * @param binaryFormat The optional binary format.
   * @param jobIndex The optional job index.
   * @returns A promise that resolves to the numeric array.
   */
  protected async loadNumericArray(url: string, binaryFormat: ChannelBinaryFormat | undefined, jobIndex?: number): Promise<ReadonlyArray<number>> {
    return this.loadWithRetries(() => super.loadNumericArray(url + this.getAccessSignature(jobIndex), binaryFormat));
  }

  /**
   * Returns whether the job index is defined.
   * @param jobIndex The job index.
   * @returns True if the job index is defined.
   */
  protected hasJobIndex(jobIndex: number) {
    return typeof jobIndex !== 'undefined';
  }

  /**
   * Runs the given delegate, retrying if necessary.
   * @param delegate The delegate to run.
   * @returns The result of the delegate.
   */
  private async loadWithRetries<T>(delegate: () => Promise<T>) {
    let attempt = 1;
    while (attempt < MAX_ATTEMPTS) {
      try {
        return await delegate();
      } catch (error) {
        await this.getNewAccessSignatures(error);
        await this.timer.backoff(attempt, 100);
      }
    }

    return await delegate();
  }

  /**
   * Gets the access signature for the given job index.
   * @param jobIndex The job index.
   * @returns The access signature.
   */
  private getAccessSignature(jobIndex: number) {
    if (this.hasJobIndex(jobIndex) && this.accessInformation.jobs) {
      // We need job access signature and study access information has been passed in.
      return this.accessInformation.jobs[jobIndex % this.accessInformation.jobs.length].accessSignature;
    }

    // Either we need a study access signature, or we need a job access signature
    // and the job access information has been passed in.
    return this.accessInformation.accessSignature;
  }

  /**
   * Gets new access signatures if the error is a 403.
   * @param error The error.
   * @param jobIndex The job index.
   */
  private async getNewAccessSignatures(error: HttpErrorResponse, jobIndex?: number): Promise<any> {
    if (error.status === 403) {
      if (!this.hasJobIndex(jobIndex) || this.accessInformation.jobs) {
        let studyResult = await this.studyStub.getStudyMetadata(this.tenantId, this.studyId);
        this.accessInformation = studyResult.accessInformation;
      } else {
        let jobResult = await this.studyStub.getStudyJobMetadata(this.tenantId, this.studyId, getJobIdFromJobIndex(this.studyId, jobIndex));
        this.accessInformation = jobResult.accessInformation;
      }
    }
  }
}

/**
 * The access information.
 */
export interface AccessInformation {

  /**
   * The base URL.
   */
  url: string;

  /**
   * The access signature to be appended to the full URL.
   */
  accessSignature: string;

  /**
   * The access information for jobs. Note this is only as long as there are shards,
   * and they are ordered such that job 0 can use the access information at index 0, etc.
   */
  jobs?: {

    /**
     * The job base URL.
     */
    url: string;

    /**
     * The job access signature to be appended to the full URL.
     */
    accessSignature: string;
  }[];
}
