import {ChartId} from './canopy-site-hooks.service';
import {UserSettingsCache} from '../../user-state/user-settings-cache.service';
import {Injectable} from '@angular/core';
import {ChartSettings, DocumentSubType} from '../../../generated/api-stubs';
import {RequestedLayoutIds} from '../../visualizations/site-hooks';

/**
 * Service for getting the preferred chart from a set of layout IDs.
 * Sometimes there are multiple layout IDs when trying to find the default chart layout
 * for a given chart type which either has legacy domains (e.g. tLap which was superseded by tRun)
 * or aliases (e.g. t, for time).
 * We want to find the user's default chart (as in the chart they set as their default) for the set of layout IDs,
 * taking the first one we find.
 */
@Injectable()
export class GetPreferredChart {

  /**
   * Creates a new instance of the GetPreferredChart service.
   * @param userSettingsCache The user settings cache.
   */
  constructor(
    private userSettingsCache: UserSettingsCache){
  }

  /**
   * Gets the preferred chart from a set of layout IDs.
   * Sometimes there are multiple layout IDs when trying to find the default chart layout
   * for a given chart type which either has legacy domains (e.g. tLap which was superseded by tRun)
   * or aliases (e.g. t, for time).
   * We want to find the user's default chart (as in the chart they set as their default) for the set of layout IDs,
   * taking the first one we find.
   * @param chartType The chart type.
   * @param layoutIds The layout IDs.
   * @returns The preferred chart.
   */
  public async execute(chartType: DocumentSubType, layoutIds: RequestedLayoutIds): Promise<ChartId>{
    if(!layoutIds){
      return undefined;
    }

    let allLayoutIds = [layoutIds.primary, ...layoutIds.fallback];
    let userSettings = await this.userSettingsCache.get();

    let chartSettings: ChartSettings;
    for(let layoutId of allLayoutIds){
      chartSettings = userSettings.settings.charts.find(v => v.chartType === chartType && v.chartId === layoutId);
      if(chartSettings){
        break;
      }
    }

    if(!chartSettings){
      return undefined;
    }

    return {
      userId: chartSettings.preferredChartUserId,
      configId: chartSettings.preferredChartId
    };
  }
}
