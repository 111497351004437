import {ChartId} from './canopy-site-hooks.service';
import {UserSettings} from '../../user-state/user-settings.service';
import {Injectable} from '@angular/core';
import {DocumentSubType} from '../../../generated/api-stubs';
import {RequestedLayoutIds} from '../../visualizations/site-hooks';

/**
 * Service for setting the preferred chart for a user.
 */
@Injectable()
export class SetPreferredChart {

  /**
   * Creates a new instance of the SetPreferredChart service.
   * @param userSettings The user settings.
   */
  constructor(
    private userSettings: UserSettings){
  }

  /**
   * Sets the preferred chart for a user.
   * @param tenantId The tenant ID.
   * @param userId The user ID.
   * @param chartType The chart type.
   * @param layoutIds The layout IDs.
   * @param preferredChartIdObject The preferred chart ID object.
   * @returns A promise that resolves when the preferred chart has been set.
   */
  public async execute(tenantId: string, userId: string, chartType: DocumentSubType, layoutIds: RequestedLayoutIds, preferredChartIdObject: ChartId): Promise<any>{
    if(!layoutIds){
      return;
    }

    let layoutId = layoutIds.primary;

    let preferredChartUserId: string;
    let preferredChartId: string;
    if(preferredChartIdObject){
      if(typeof preferredChartIdObject === 'string'){
        preferredChartId = preferredChartIdObject;
      } else{
        preferredChartUserId = preferredChartIdObject.userId;
        preferredChartId = preferredChartIdObject.configId;
      }
    }

    // If the user is setting their preferred chart to the default chart, we remove the setting.
    // If the user is not supplying a preferred chart ID, we remove the setting.
    let removeSetting = !preferredChartIdObject || preferredChartId === layoutId;

    await this.userSettings.update(tenantId, userId, currentSettings => {

      // Find the existing chart settings.
      let chartSettings = currentSettings.settings.charts.find(v => v.chartType === chartType && v.chartId === layoutId);

      if(!removeSetting){
        // If we're adding a setting...
        if(chartSettings){
          // If the setting exists, update it.
          chartSettings.preferredChartId = preferredChartId;
          chartSettings.preferredChartUserId = preferredChartUserId;
        } else{
          // Otherwise add the setting.
          currentSettings.settings.charts.push({
            chartType,
            chartId: layoutId,
            preferredChartId,
            preferredChartUserId
          });
        }
      } else{
        // If we're removing the setting...
        if(chartSettings){
          // If the setting exists, remove it.
          let index = currentSettings.settings.charts.indexOf(chartSettings);
          currentSettings.settings.charts.splice(index, 1);
        }
      }

      // Search for fallback chart IDs and remove any settings for those, as they are superseded by the primary ID.
      for(let fallbackId of layoutIds.fallback){
        let index = currentSettings.settings.charts.findIndex(v => v.chartType === chartType && v.chartId === fallbackId);
        if(index !== -1){
          currentSettings.settings.charts.splice(index, 1);
        }
      }
    });

    return;
  }
}
