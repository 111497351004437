import {
  UrlFileLoader,
  StudyScalarResults,
  ChannelMetadata,
  SingleScalarResult,
  ChannelBinaryFormat,
  StudyMetadata
} from '../../visualizations/url-file-loader';
import {ExplorationMap} from '../../visualizations/viewers/channel-data-loaders/exploration-map';
import {CanopyError, DisplayableError} from '../../common/errors/errors';
import {
  StudyStub,
  SimType,
  PostStudyInlineResult,
  GetStudyJobQueryResult,
  GetStudyQueryResult
} from '../../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {RetryingFileLoaderBase, AccessInformation} from './retrying-file-loader-base';
import {PopulateTrackRacingLineFromSimulation} from '../../visualizations/populate-track-racing-line-from-simulation';
import {JobViewModel} from '../jobs/job-results/job-view-model';
import { Timer } from '../../common/timer.service';

/**
 * Factory for creating instances of CanopyFileLoader.
 */
@Injectable()
export class CanopyFileLoaderFactory {

  /**
   * Creates a new instance of CanopyFileLoaderFactory.
   * @param studyStub The study stub.
   * @param timer The timer service.
   */
  constructor(
    private readonly studyStub: StudyStub,
    private readonly timer: Timer){
  }

  /**
   * Creates a new instance of CanopyFileLoader.
   * @returns A new instance of CanopyFileLoader.
   */
  public create(): CanopyFileLoader {
    return new CanopyFileLoader(
      this.studyStub,
      this.timer);
  }
}

/**
 * Represents the data for loading a study .
 */
interface StudyData {

  /**
   * The tenant ID of the study.
   */
  tenantId: string;

  /**
   * The study ID.
   */
  studyId: string;

  /**
   * The file loader for the study.
   */
  fileLoader: CachingStudyFileLoader | InlineStudyFileLoader;
}

/**
 * The Canopy file loader implementation. This implements the interface required by visualizations to load data.
 */
export class CanopyFileLoader implements UrlFileLoader {

  /**
   * The map of study IDs to study data.
   */
  private studyMap: { [studyId: string]: StudyData };

  /**
   * Creates a new instance of CanopyFileLoader.
   * @param studyStub The study stub.
   * @param timer The timer service.
   */
  constructor(private readonly studyStub: StudyStub, private readonly timer: Timer){
    this.studyMap = {};
  }

  /**
   * Adds a study to the file loader.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param accessInformation The access information for the study.
   * @param simVersion The sim version.
   */
  public addStudy(
    tenantId: string,
    studyId: string,
    accessInformation: AccessInformation,
    simVersion: string){

    if(this.studyMap[studyId]){
      return;
    }

    this.studyMap[studyId] = {
      tenantId,
      studyId,
      fileLoader: new CachingStudyFileLoader(
        tenantId,
        studyId,
        this.studyStub,
        this.timer,
        accessInformation,
        simVersion)
    };
  }

  /**
   * Adds an inline study to the file loader.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param inlineStudyResult The inline study result.
   */
  public addInlineStudy(
    tenantId: string,
    studyId: string,
    inlineStudyResult: PostStudyInlineResult){

    if(this.studyMap[studyId]){
      return;
    }

    this.studyMap[studyId] = {
      tenantId,
      studyId,
      fileLoader: new InlineStudyFileLoader(
        inlineStudyResult)
    };
  }

  /**
   * Adds a study job to the file loader.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param jobViewModel The job view model.
   */
  public addStudyJob(
    tenantId: string,
    studyId: string,
    jobIndex: number,
    jobViewModel: JobViewModel){

    let studyFileLoader = this.studyMap[studyId];
    if(!studyFileLoader){
      throw new CanopyError('No study data set for ' + studyId);
    }

    if(studyFileLoader.fileLoader instanceof CachingStudyFileLoader){
      studyFileLoader.fileLoader.addStudyJob(jobIndex, jobViewModel);
    }
  }

  /**
   * @inheritdoc
   */
  public loadVectorMetadata(studyId: string, jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    return this.getFileLoader(studyId).loadVectorMetadata(jobIndex, simType);
  }

  /**
   * @inheritdoc
   */
  public loadScalarResultsForSim(studyId: string, jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    return this.getFileLoader(studyId).loadScalarResultsForSim(jobIndex, simType);
  }

  /**
   * @inheritdoc
   */
  public loadScalarResultsForStudy(studyId: string): Promise<StudyScalarResults> {
    return this.getFileLoader(studyId).loadScalarResultsForStudy();
  }

  /**
   * @inheritdoc
   */
  public async loadChannelDataAndBinaryFormat(studyId: string, jobIndex: number, simType: SimType, channelName: string): Promise<ReadonlyArray<number>> {
    let vectorMetadata = await this.loadVectorMetadata(studyId, jobIndex, simType);
    let channel = vectorMetadata.find(v => v.name === channelName);
    let binaryFormat = channel ? channel.binaryFormat : undefined;
    return await this.loadChannelData(studyId, jobIndex, simType, channelName, binaryFormat);
  }

  /**
   * @inheritdoc
   */
  public loadChannelData(studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.getFileLoader(studyId).loadChannelData(jobIndex, simType, channelName, binaryFormat);
  }

  /**
   * @inheritdoc
   */
  public loadChannelDataByFileName(studyId: string, jobIndex: number, fileName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.getFileLoader(studyId).loadChannelDataByFileName(jobIndex, fileName, binaryFormat);
  }

  /**
   * @inheritdoc
   */
  public loadJsonDataByFileName(studyId: string, jobIndex: number, fileName: string): Promise<any> {
    return this.getFileLoader(studyId).loadJsonDataByFileName(jobIndex, fileName);
  }

  /**
   * @inheritdoc
   */
  public loadExplorationMap(studyId: string): Promise<ExplorationMap> {
    return this.getFileLoader(studyId).loadExplorationMap();
  }

  /**
   * @inheritdoc
   */
  public loadNavigationStationConfig(configId: string): Promise<any> {
    throw new CanopyError('Loading navigation station configs is not supported.');
  }

  /**
   * @inheritdoc
   */
  public loadChartLayout(layoutId: string): Promise<any> {
    throw new CanopyError('Chart layouts should be loaded through site hooks: ' + layoutId);
  }

  /**
   * @inheritdoc
   */
  public loadCsv(fileName: string): Promise<any>{
    throw new CanopyError('Method loadCsv should never be called directly.');
  }

  /**
   * @inheritdoc
   */
  public loadTrackForStudy(studyId: string): Promise<any> {
    return this.getFileLoader(studyId).loadTrackForStudy();
  }

  /**
   * @inheritdoc
   */
  public async loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.getFileLoader(studyId).loadTrackForStudyJob(jobIndex);
  }

  /**
   * @inheritdoc
   */
  public loadCarForStudy(studyId: string): Promise<any>{
    return this.getFileLoader(studyId).loadCarForStudy();
  }

  /**
   * @inheritdoc
   */
  public loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.getFileLoader(studyId).loadCarForStudyJob(jobIndex);
  }

  /**
   * @inheritdoc
   */
  public loadStudyMetadata(studyId: string): Promise<StudyMetadata> {
    return this.getFileLoader(studyId).loadStudyMetadata();
  }

  /**
   * Gets the file loader for the given study ID.
   * @param studyId The study ID.
   * @returns The file loader.
   */
  private getFileLoader(studyId: string){
    return this.getStudyData(studyId).fileLoader;
  }

  /**
   * Gets the study data for the given study ID.
   * @param studyId The study ID.
   * @returns The study data.
   */
  private getStudyData(studyId: string): StudyData{
    let studyData = this.studyMap[studyId];
    if(!studyData){
      throw new CanopyError('No study data set for ' + studyId);
    }

    return studyData;
  }
}

/**
 * A loader for inline studies (studies which were run on the API servers and so return all their results in one structure).
 */
export class InlineStudyFileLoader {

  /**
   * The study vector metadata by sim type.
   */
  private vectorMetadata: { [simType: string]: ChannelMetadata[] } = {};

  /**
   * The study scalar results by sim type.
   */
  private scalarResults: { [simType: string]: SingleScalarResult[] } = {};

  /**
   * Creates a new instance of InlineStudyFileLoader.
   * @param inlineStudyResult The inline study result.
   */
  constructor(private readonly inlineStudyResult: PostStudyInlineResult){
  }

  /**
   * Loads the vector metadata for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The vector metadata.
   */
  public loadVectorMetadata(jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    if(this.vectorMetadata[simType]){
      return Promise.resolve(this.vectorMetadata[simType]);
    }

    if(!this.inlineStudyResult || !this.inlineStudyResult.vectorResults){
      return Promise.resolve([]);
    }

    let result: ChannelMetadata[] = [];
    let simTypeData = this.inlineStudyResult.vectorResults[simType];
    if(simTypeData){
      for(let channelName in simTypeData) {
        if (!simTypeData.hasOwnProperty(channelName)) {
          continue;
        }

        let channel = simTypeData[channelName];
        result.push({
          name: channelName,
          units: channel.units,
          description: channel.description,
          xDomainName: channel.xDomainName,
          simType,
          binaryFormat: { pointsCount: channel.values.length }
        });
      }
    }

    this.vectorMetadata[simType] = result;
    return Promise.resolve(result);
  }

  /**
   * Loads the scalar results for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The scalar results.
   */
  public loadScalarResultsForSim(jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    if(this.scalarResults[simType]){
      return Promise.resolve(this.scalarResults[simType]);
    }

    if(!this.inlineStudyResult || !this.inlineStudyResult.scalarResults){
      return Promise.resolve([]);
    }

    let result: SingleScalarResult[] = [];
    let simTypeData = this.inlineStudyResult.scalarResults[simType];
    if(simTypeData){
      for(let channelName in simTypeData) {
        if (!simTypeData.hasOwnProperty(channelName)) {
          continue;
        }

        let channel = simTypeData[channelName];
        result.push({
          name: channelName,
          units: channel.units,
          description: channel.description,
          value: channel.value
        });
      }
    }

    this.scalarResults[simType] = result;
    return Promise.resolve(result);
  }

  /**
   * Loads the scalar results for the study. Not implemented for inline studies.
   * @returns The scalar results.
   */
  public loadScalarResultsForStudy(): Promise<StudyScalarResults> {
    throw new Error('Not supported.');
  }

  /**
   * Loads the channel data for the given job, sim type and channel.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @param channelName The channel name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelData(jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    if(!this.inlineStudyResult || !this.inlineStudyResult.vectorResults){
      return Promise.resolve(undefined);
    }

    let simTypeData = this.inlineStudyResult.vectorResults[simType];
    if(simTypeData){
      let channelData = simTypeData[channelName];
      if(channelData){
        return Promise.resolve(channelData.values);
      }
    }

    return Promise.resolve(undefined);
  }

  /**
   * Loads the channel data for the given job and file name. Not implemented for inline studies.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelDataByFileName(jobIndex: number, fileName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    throw new Error('Not supported.');
  }

  /**
   * Loads the JSON data for the given job and file name. Not implemented for inline studies.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @returns The JSON data.
   */
  public loadJsonDataByFileName(jobIndex: number, fileName: string): Promise<any> {
    throw new Error('Not supported.');
  }

  /**
   * Loads the exploration map. Not implemented for inline studies.
   * @returns The exploration map.
   */
  public loadExplorationMap(): Promise<ExplorationMap> {
    throw new Error('Not supported.');
  }

  /**
   * Loads the track for the study.
   * @returns The track.
   */
  public loadTrackForStudy(): Promise<any> {
    return Promise.resolve(this.inlineStudyResult.study.data.definition.simConfig.track);
  }

  /**
   * Loads the track for the given job.
   * @param jobIndex The job index.
   * @returns The track.
   */
  public async loadTrackForStudyJob(jobIndex: number): Promise<any> {
    return Promise.resolve(this.inlineStudyResult.study.data.definition.simConfig.track);
  }

  /**
   * Loads the car for the study.
   * @returns The car.
   */
  public async loadCarForStudy(): Promise<any>{
    return Promise.resolve(this.inlineStudyResult.study.data.definition.simConfig.car);
  }

  /**
   * Loads the car for the given job.
   * @param jobIndex The job index.
   * @returns The car.
   */
  public async loadCarForStudyJob(jobIndex: number): Promise<any>{
    return Promise.resolve(this.inlineStudyResult.study.data.definition.simConfig.car);
  }

  /**
   * Loads the study metadata.
   * @returns The study metadata.
   */
  public loadStudyMetadata(): Promise<StudyMetadata> {
    return Promise.resolve(
      new StudyMetadata(
        undefined,
        this.inlineStudyResult.study.simVersion));
  }
}

/**
 * A loader for studies which caches already requested data.
 */
export class CachingStudyFileLoader {

  /**
   * The inner study file loader.
   */
  private inner: StudyFileLoader;

  /**
   * The cache of loaded data.
   */
  private cache: any;

  /**
   * Creates a new instance of CachingStudyFileLoader.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param studyStub The study stub.
   * @param timer The timer service.
   * @param accessInformation The access information for the study.
   * @param simVersion The sim version.
   */
  constructor(tenantId: string,
              studyId: string,
              studyStub: StudyStub,
              timer: Timer,
              accessInformation: AccessInformation,
              simVersion: string){
    this.inner = new StudyFileLoader(this, tenantId, studyId, studyStub, timer, accessInformation, simVersion);
    this.cache = {};
  }

  /**
   * Adds a study job to the file loader.
   * @param jobIndex The job index.
   * @param jobViewModel The job view model.
   */
  public addStudyJob(
    jobIndex: number,
    jobViewModel: JobViewModel){

    this.inner.addStudyJob(jobIndex, jobViewModel);
  }

  /**
   * Gets or adds a value to the cache for the given key.
   * @param key The key.
   * @param addDelegate The delegate to get the value to add if it is not in the cache.
   * @returns The value.
   */
  private getOrAdd(key: string, addDelegate: () => Promise<any>) {
    let result = this.cache[key];
    if(!result){
      result = addDelegate();
      this.cache[key] = result;
    }
    return result;
  }

  /**
   * Loads the vector metadata for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The vector metadata.
   */
  public loadVectorMetadata(jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    return this.getOrAdd(
      `loadVectorMetadata-${jobIndex}-${simType}`,
      () => this.inner.loadVectorMetadata(jobIndex, simType));
  }

  /**
   * Loads the scalar results for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The scalar results.
   */
  public loadScalarResultsForSim(jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    return this.getOrAdd(
      `loadScalarResultsForSim-${jobIndex}-${simType}`,
      () => this.inner.loadScalarResultsForSim(jobIndex, simType));
  }

  /**
   * Loads the scalar results for the study.
   * @returns The scalar results.
   */
  public loadScalarResultsForStudy(): Promise<StudyScalarResults> {
    return this.getOrAdd(
      `loadScalarResultsForStudy`,
      () => this.inner.loadScalarResultsForStudy());
  }

  /**
   * Loads the channel data for the given job, sim type and channel.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @param channelName The channel name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelData(jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.getOrAdd(
      `loadChannelData-${jobIndex}-${simType}-${channelName}`,
      () => this.inner.loadChannelData(jobIndex, simType, channelName, binaryFormat));
  }

  /**
   * Loads the channel data for the given job and file name.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelDataByFileName(jobIndex: number, fileName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.getOrAdd(
      `loadChannelDataByFileName-${jobIndex}-${fileName}`,
      () => this.inner.loadChannelDataByFileName(jobIndex, fileName, binaryFormat));
  }

  /**
   * Loads the JSON data for the given job and file name.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @returns The JSON data.
   */
  public loadJsonDataByFileName(jobIndex: number, fileName: string): Promise<any> {
    return this.getOrAdd(
      `loadJsonDataByFileName-${jobIndex}-${fileName}`,
      () => this.inner.loadJsonDataByFileName(jobIndex, fileName));
  }

  /**
   * Loads the exploration map.
   * @returns The exploration map.
   */
  public loadExplorationMap(): Promise<ExplorationMap> {
    return this.getOrAdd(
      `loadExplorationMap`,
      () => this.inner.loadExplorationMap());
  }

  /**
   * Loads the track for the study.
   * @returns The track.
   */
  public async loadTrackForStudy(): Promise<any> {
    return this.getOrAdd(
      `loadTrackForStudy`,
      () => this.inner.loadTrackForStudy());
  }

  /**
   * Loads the track for the given job.
   * @param jobIndex The job index.
   * @returns The track.
   */
  public async loadTrackForStudyJob(jobIndex: number): Promise<any> {
    return this.getOrAdd(
      `loadTrackForStudyJob-${jobIndex}`,
      () => this.inner.loadTrackForStudyJob(jobIndex));
  }

  /**
   * Loads the car for the study.
   * @returns The car.
   */
  public async loadCarForStudy(): Promise<any>{
    return this.getOrAdd(
      `loadCarForStudy`,
      () => this.inner.loadCarForStudy());
  }

  /**
   * Loads the car for the given job.
   * @param jobIndex The job index.
   * @returns The car.
   */
  public async loadCarForStudyJob(jobIndex: number): Promise<any>{
    return this.getOrAdd(
      `loadCarForStudyJob-${jobIndex}`,
      () => this.inner.loadCarForStudyJob(jobIndex));
  }

  /**
   * Loads the study metadata.
   * @returns The study metadata.
   */
  public loadStudyMetadata(): Promise<StudyMetadata> {
    return this.getOrAdd(
      `loadStudyMetadata`,
      () => this.inner.loadStudyMetadata());
  }
}

/**
 * The inner file loader for a study.
 */
class StudyFileLoader extends RetryingFileLoaderBase {

  /**
   * The study URL.
   */
  private studyUrl: string;

  /**
   * The job URLs.
   */
  private jobUrls: string[];

  /**
   * The map of job indices to job view models.
   */
  private jobViewModels: { [jobIndex: number]: JobViewModel } = {};

  /**
   * The last job view model added.
   */
  private lastJobViewModel: JobViewModel;

  /**
   * Creates a new instance of StudyFileLoader.
   * @param cache The caching study file loader. We pass this in so that we can fetch any required supporting data from the cache.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param studyStub The study stub.
   * @param timer The timer service.
   * @param accessInformation The access information for the study.
   * @param simVersion The sim version.
   */
  constructor(
    private cache: CachingStudyFileLoader,
    tenantId: string,
    studyId: string,
    studyStub: StudyStub,
    timer: Timer,
    accessInformation: AccessInformation,
    private readonly simVersion: string){
    super(tenantId, studyId, studyStub, timer, accessInformation);

    this.studyUrl = accessInformation.url;
    this.jobUrls = accessInformation.jobs.map(v => v.url);
  }

  /**
   * Adds a study job to the file loader.
   * @param jobIndex The job index.
   * @param jobViewModel The job view model.
   */
  public addStudyJob(
    jobIndex: number,
    jobViewModel: JobViewModel){

    if(this.jobViewModels[jobIndex]){
      return;
    }

    this.jobViewModels[jobIndex] = jobViewModel;
    this.lastJobViewModel = jobViewModel;
  }

  /**
   * Loads the vector metadata for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The vector metadata.
   */
  public async loadVectorMetadata(jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    let jobUrl = this.getJobUrl(jobIndex);
    let rawMetadata = await this.loadCsv(jobUrl + encodeURIComponent(simType) + '_VectorMetadata.csv', jobIndex);
    return (rawMetadata || []).map(v => ({
      name: v.name,
      simType,
      description: v.description,
      units: v.units,
      xDomainName: v.xDomainName,
      binaryFormat: new ChannelBinaryFormat(+v.NPtsInChannel),
    }));
  }

  /**
   * Loads the scalar results for the given job and sim type.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @returns The scalar results.
   */
  public loadScalarResultsForSim(jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    let jobUrl = this.getJobUrl(jobIndex);
    return this.loadCsv(jobUrl + encodeURIComponent(simType) + '_ScalarResults.csv', jobIndex);
  }

  /**
   * Loads the scalar results for the study.
   * @returns The scalar results.
   */
  public async loadScalarResultsForStudy(): Promise<StudyScalarResults> {
    let resultsTask = this.loadCsvRows(this.studyUrl + 'scalar-results.csv');
    let metadataTask = this.loadCsv(this.studyUrl + 'scalar-metadata.csv');
    let inputsMetadata = await this.loadCsv(this.studyUrl + 'scalar-inputs-metadata.csv');
    let results = await resultsTask;
    let metadata = await metadataTask;

    let parsedResults = this.createScalarResultsUsingScalarMetadata(results, metadata);
    return {
      data: parsedResults,
      metadata,
      inputsMetadata: inputsMetadata || []
    };
  }

  /**
   * Loads the channel data for the given job, sim type and channel.
   * @param jobIndex The job index.
   * @param simType The sim type.
   * @param channelName The channel name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelData(jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.cache.loadChannelDataByFileName(jobIndex, encodeURIComponent(simType) + '_' + encodeURIComponent(channelName) + '.bin', binaryFormat);
  }

  /**
   * Loads the channel data for the given job and file name.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @param binaryFormat The binary format for the channel.
   * @returns The channel data.
   */
  public loadChannelDataByFileName(jobIndex: number, fileName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    let jobUrl = this.getJobUrl(jobIndex);
    return this.loadNumericArray(jobUrl + fileName, binaryFormat, jobIndex);
  }

  /**
   * Loads the JSON data for the given job and file name.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @returns The JSON data.
   */
  public loadJsonDataByFileName(jobIndex: number, fileName: string): Promise<ReadonlyArray<number>> {
    let jobUrl = this.getJobUrl(jobIndex);
    return this.loadJson(jobUrl + fileName, jobIndex);
  }

  /**
   * Loads the exploration map.
   * @returns The exploration map.
   */
  public loadExplorationMap(): Promise<ExplorationMap> {
    return this.loadJson(this.studyUrl + 'exploration-map.json');
  }

  /**
   * Loads the track for the study.
   * @returns The track.
   */
  public async loadTrackForStudy(): Promise<any> {
    let studyResult = await this.getStudy();
    return studyResult.study.data.definition.simConfig.track;
  }

  /**
   * Loads the track for the given job.
   * @param jobIndex The job index.
   * @returns The track.
   */
  public async loadTrackForStudyJob(jobIndex: number): Promise<any> {
    let studyResult = await this.getStudyMetadata();
    let jobResult = await this.getStudyJob(jobIndex);

    if(!jobResult.studyJobInput.simTypes.length) {
      throw new DisplayableError('No job sim types found for job.');
    }
    let simType: SimType = jobResult.studyJobInput.simTypes[0];
    let studyType = studyResult.study.data.studyType;
    let vectorMetadata = await this.cache.loadVectorMetadata(jobIndex, simType);
    let populateRacingLine = new PopulateTrackRacingLineFromSimulation(
      studyType,
      jobResult.studyJob.simVersion,
      vectorMetadata,
      (fileName, binaryFormat) => this.cache.loadChannelDataByFileName(jobIndex, fileName, binaryFormat),
      (fileName) => this.cache.loadJsonDataByFileName(jobIndex, fileName));

    return await populateRacingLine.execute(jobResult.studyJobInput.simConfig.track);
  }

  /**
   * Loads the car for the study.
   * @returns The car.
   */
  public async loadCarForStudy(): Promise<any>{
    let studyResult = await this.getStudy();
    return studyResult.study.data.definition.simConfig.car;
  }

  /**
   * Loads the car for the given job.
   * @param jobIndex The job index.
   * @returns The car.
   */
  public async loadCarForStudyJob(jobIndex: number): Promise<any>{
    let jobResult = await this.getStudyJob(jobIndex);
    return jobResult.studyJobInput.simConfig.car;
  }

  /**
   * Loads the study metadata.
   * @returns The study metadata.
   */
  public async loadStudyMetadata(): Promise<StudyMetadata> {
    return new StudyMetadata(
      this.studyId,
      this.simVersion);

  }

  /**
   * Gets the URL for the given job index.
   * @param jobIndex The job index.
   * @returns The URL.
   */
  private getJobUrl(jobIndex: number){
    if(!super.hasJobIndex(jobIndex)) {
      throw new CanopyError('JobIndex expected');
    }

    return this.jobUrls[jobIndex % this.jobUrls.length] + jobIndex + '/';
  }

  /**
   * Gets the job ID for the given job index.
   * @param jobIndex The job index.
   * @returns The job ID.
   */
  private getJobId(jobIndex: number): string{
    return `${this.studyId}-${jobIndex}`;
  }

  /**
   * Gets the study job query result for the given job index.
   * @param jobIndex The job index.
   * @returns The study job query result.
   */
  private async getStudyJob(jobIndex: number): Promise<GetStudyJobQueryResult> {
    let jobViewModel = this.jobViewModels[jobIndex];
    if(jobViewModel){
      await jobViewModel.jobResult.load();
      return jobViewModel.jobResult.value;
    }

    let jobId = this.getJobId(jobIndex);
    return await this.studyStub.getStudyJob(this.tenantId, this.studyId, jobId, this.simVersion);
  }

  /**
   * Gets the study metadata result for the current study.
   * @returns The study metadata.
   */
  private async getStudyMetadata(): Promise<GetStudyQueryResult> {
    if(this.lastJobViewModel){
      await this.lastJobViewModel.studyMetadataResult.load();
      return this.lastJobViewModel.studyMetadataResult.value;
    }

    return await this.studyStub.getStudyMetadata(this.tenantId, this.studyId);
  }

  /**
   * Gets the study result for the current study.
   * @returns The study result.
   */
  private async getStudy(): Promise<GetStudyQueryResult> {
    if(this.lastJobViewModel){
      await this.lastJobViewModel.studyResult.load();
      return this.lastJobViewModel.studyResult.value;
    }

    return await this.studyStub.getStudy(this.tenantId, this.studyId, this.simVersion);
  }
}
