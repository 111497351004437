import * as d3 from '../../../d3-bundle';
import { Utilities } from '../../../utilities';
import { ChannelDataList } from './channel-data-list';

import {get_interpolant, get_values} from 'rbf_rust';

/**
 * A radial basis function interpolator.
 */
export class RadialBasisFunction {
  private channelMetadata?: RBFChannelMetadataMap;

  private resultsAtLastPoint?: ReadonlyArray<number>;

  /**
   * Initializes a new instance of RadialBasisFunction.
   * @param rCoordinates The normalized coordinates for each job.
   * The outer array is the job and the inner array is the sweep.
   * @param channelList The channel list.
   */
  constructor(
    private readonly rCoordinates: ReadonlyArray<ReadonlyArray<number>>,
    private readonly channelList: ChannelDataList) {
  }

  /**
   * Trains the RBF.
   */
  public train(): void {
    let adjustedData = [];
    let channelMetadata: RBFChannelMetadataMap = {};
    let channelIndex = 0;
    for (let channel of this.channelList.channels) {
      let data = channel.values;
      if (!data) {
        continue;
      }
      let mean = d3.meanStrict(data);
      adjustedData.push(data.map(v => v - mean));
      channelMetadata[channel.name] = new RBFChannelMetadata(channelIndex, mean);
      channelIndex++;
    }

    adjustedData = Utilities.transpose(adjustedData);
    // interpolant stored in WASM
    get_interpolant(this.rCoordinates, adjustedData);
    this.channelMetadata = channelMetadata;
  }

  /**
   * Sets the coordinates for the interpolator.
   * @param rPoint The relative coordinates for a point in the hypercube.
   */
  public setCoordinates(rPoint: ReadonlyArray<number>): void {
    this.resultsAtLastPoint = get_values(rPoint);
  }

  /**
   * Gets the value of a channel at the last point set by setCoordinates.
   * @param channelName The name of the channel.
   * @returns The value of the channel at the last point set by setCoordinates.
   */
  public getChannelValue(channelName: string): number {
    if (!this.resultsAtLastPoint || !this.channelMetadata) {
      return NaN;
    }

    let metadata = this.channelMetadata[channelName];
    if (!metadata) {
      return NaN;
    }

    return this.resultsAtLastPoint[metadata.index] + metadata.mean;
  }
}

/**
 * A mapping of channel name to RBF channel metadata.
 */
interface RBFChannelMetadataMap {
  [channelName: string]: RBFChannelMetadata;
}

/**
 * Metadata for a channel in an RBF.
 */
class RBFChannelMetadata {
  constructor(
    public readonly index: number,
    public readonly mean: number) {
  }
}
