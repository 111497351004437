import {Injectable} from '@angular/core';
import {ConfigStub, DocumentSubType} from '../../../generated/api-stubs';
import {
  ChartId, SavedChartResult, SimVersionChartId,
  UserChartId
} from './canopy-site-hooks.service';
import {ConfigLoaderDialog} from '../configs/config-loader-dialog/config-loader-dialog.service';
import {ConfigLoaderUtilities} from '../configs/config-loader-utilities.service';
import {ConfigTypeLookup} from '../configs/config-types';
import {CanopyError} from '../../common/errors/errors';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {SaveAsDialog} from '../../common/dialogs/save-as-dialog.service';
import {StudyInput} from '../../worksheets/study-input';

/**
 * The factory for creating a chart repository for a tenant.
 */
@Injectable()
export class ChartRepositoryFactory {

  /**
   * Creates a new chart repository factory.
   * @param chartLoaderUtilities The chart loader utilities.
   * @param configStub The config stub.
   * @param chartLoaderDialog The chart loader dialog.
   * @param getSimVersion The get sim version service.
   * @param saveAsDialog The save as dialog service.
   */
  constructor(
    private readonly chartLoaderUtilities: ConfigLoaderUtilities,
    private readonly configStub: ConfigStub,
    private readonly chartLoaderDialog: ConfigLoaderDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveAsDialog: SaveAsDialog){
  }

  /**
   * Creates a new chart repository for the given tenant.
   * @param tenantId The tenant ID.
   * @returns The chart repository.
   */
  public create(tenantId: string){
    return new ChartRepository(
      tenantId,
      this.getSimVersion.currentSimVersion,
      this.chartLoaderUtilities,
      this.configStub,
      this.chartLoaderDialog,
      this.saveAsDialog);
  }
}

/**
 * The chart repository.
 */
export class ChartRepository {

  /**
   * Creates a new chart repository for a tenant.
   * @param tenantId The tenant ID.
   * @param simVersion The simulation version.
   * @param configLoaderUtilities The config loader utilities.
   * @param configStub The config stub.
   * @param configLoaderDialog The config loader dialog.
   * @param saveAsDialog The save as dialog.
   */
  constructor(
    private readonly tenantId: string,
    private readonly simVersion: string,
    private readonly configLoaderUtilities: ConfigLoaderUtilities,
    private readonly configStub: ConfigStub,
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly saveAsDialog: SaveAsDialog){
  }

  /**
   * Loads a chart for the given chart type and optional chart ID.
   * @param chartType The chart type.
   * @param chartId The chart ID.
   * @returns The chart config, or undefined if no chart config could be loaded.
   */
  public async loadChart(chartType: DocumentSubType, chartId?: ChartId): Promise<StudyInput> {
    let simVersionChartId = <SimVersionChartId>chartId;
    let userChartId = <UserChartId>chartId;

    if(!chartId){
      // No chart id specified, so load the default chart for the chart type.
      // Sometimes there is no default chart, in which case we don't display a visualization.
      let result = await this.configLoaderDialog.loadConfig(
        chartType,
        this.simVersion);

      if(!result){
        return undefined;
      }

      return result.config;
    } else if(typeof simVersionChartId === 'string') {
      // A default chart ID was specified, so load the specified default chart.
      let configType = ConfigTypeLookup.get(chartType);
      try {
        let result = await this.configLoaderUtilities.loadSimVersionConfig(
          configType,
          this.simVersion,
          simVersionChartId);

        return result;
      } catch(error){
        if(error.response && error.response.status === 404){
          return undefined;
        } else{
          throw error;
        }
      }
    } else if(userChartId.userId){
      // A user chart ID was specified, so load the specified user chart.
      let result = await this.configLoaderUtilities.loadUserConfig(
        this.tenantId,
        userChartId.userId,
        userChartId.configId,
        this.simVersion);

      return result;
    }

    throw new CanopyError('Unknown chart ID: ' + JSON.stringify(chartId));
  }

  /**
   * Saves a chart for the given chart type and config ID.
   * @param chartType The chart type.
   * @param configId The config ID.
   * @param name The name of the chart.
   * @param chartConfig The chart config.
   */
  public async saveChart(chartType: DocumentSubType, configId: string, name: string, chartConfig: any) {
    await this.configStub.putConfig(
      this.tenantId,
      configId,
      {
        name,
        configType: chartType,
        config: chartConfig,
        simVersion: this.simVersion
      });
  }

  /**
   * Saves a chart as a new chart, prompting the user for a name.
   * @param chartType The chart type.
   * @param chartConfig The chart config.
   * @returns The saved chart metadata.
   */
  public async saveChartAs(chartType: DocumentSubType, chartConfig: any): Promise<SavedChartResult> {
    let result = await this.saveAsDialog.showForContent(
      '',
      chartType,
      this.simVersion,
      undefined,
      chartConfig,
      undefined);

    if(!result){
      return undefined;
    }

    return {
      viewerId: {
        userId: result.userId,
        configId: result.configId,
      },
      name: result.config.name
    };
  }
}
