import { SiteHooks, LoadViewerLayoutResult, RequestedLayoutIds } from '../site-hooks';
import {
  NavigationStationConfigBuilder,
  NavigationStationConfig, NavigationStationConfigView
} from './config-builders/navigation-station-config-builder';
import { ViewerWrapper } from './viewer-wrapper';
import { ButtonsViewerWrapper } from './buttons-viewer-wrapper';
import { GridStackViewerWrapper } from './grid-stack-viewer-wrapper';
import { StudyJob } from '../study-job';
import { RELOAD_DEBOUNCE_TIME } from '../constants';
import { INavigationStationCallbacks } from './navigation-station-callbacks';
import { Subscription } from 'rxjs';
import { Timer } from '../timer';
import { SharedState } from '../viewers/shared-state';
import { debounceTime } from 'rxjs/operators';
import { GridItemHTMLElement, GridStack } from 'gridstack';
import { Size } from '../viewers/size';

export const USE_FIRST_SUCCESSFUL_JOB_INDEX = -1;

/**
 * Manages a set of viewers on a page. If you're wondering about the name, ask Mark.
 */
export class NavigationStation implements INavigationStationCallbacks {

  /**
   * The config, defining the set of viewers to display, as created by a config builder.
   */
  private config?: NavigationStationConfig;

  /**
   * The viewers.
   */
  private viewers?: ViewerWrapper[];

  /**
   * The subscription to the units changed event.
   */
  private unitsChangedSubscription: Subscription = new Subscription();

  /**
   * The GridStack instance.
   */
  private grid: GridStack;

  /**
   * The ongoing async build task, if any.
   */
  private buildTask?: Promise<void>;

  /**
   * The site hooks, for interacting with the hosting site.
   */
  private readonly siteHooks: SiteHooks;

  /**
   * The set of study jobs we're working with.
   */
  private readonly studyJobs: ReadonlyArray<StudyJob>;

  /**
   * Creates a new NavigationStation.
   * @param elementId The element id we should attach to.
   * @param configBuilder The config builder to use.
   */
  constructor(
    private elementId: string,
    private configBuilder: NavigationStationConfigBuilder) {

    this.siteHooks = this.configBuilder.siteHooks;
    this.studyJobs = this.configBuilder.studyJobs;
  }

  /**
   * Disposes the navigation station.
   */
  public dispose() {
    if (this.unitsChangedSubscription) {
      this.unitsChangedSubscription.unsubscribe();
    }
    if (this.config && this.config.sharedStates) {
      for (let sharedState of this.config.sharedStates) {
        sharedState.dispose();
      }
    }
    if (this.siteHooks) {
      this.siteHooks.dispose();
    }
    if (this.viewers) {
      for (let viewer of this.viewers) {
        viewer.dispose();
      }
    }
    if(this.grid){
      this.grid.destroy();
    }
  }

  /**
   * Gets the shared states. There can be multiple shared states
   * if we're dealing with multiple domains.
   */
  public get sharedStates(): ReadonlyArray<SharedState> {
    return this.config ? this.config.sharedStates : [];
  }

  /**
   * Reloads the navigation station viewers. Waits for any existing build task
   * to complete before starting the reload.
   * @returns A promise that resolves when the reload is complete.
   */
  public reload(): Promise<void> {
    let previousTask = this.buildTask;
    this.buildTask = this.reloadInner(previousTask);
    return this.buildTask;
  }

  /**
   * Causes the navigation station viewers to redraw.
   */
  public async redraw(): Promise<void> {
    await this.buildTask;
    this.sharedStates.forEach(v => v.raiseWindowResizedNews());
  }

  /**
   * Reloads the navigation station viewers. Waits for any existing build task.
   * @param previousTask The previous task to wait for.
   */
  private async reloadInner(previousTask: Promise<void> | undefined) {
    if (previousTask) {
      await previousTask;
    }

    if (this.viewers) {
      for (let viewer of this.viewers) {
        await viewer.reload();
      }
    }
  }

  /**
   * Called when the default chart is updated for a layout ID. Forwards the call to all viewers to handle.
   * @param viewerType The viewer type.
   * @param originalLayoutId The original layout ID.
   * @param result The LoadViewerLayoutResult instance.
   */
  public async setDefaultChartUpdated(viewerType: string, originalLayoutId: RequestedLayoutIds, result: LoadViewerLayoutResult): Promise<void> {
    if (this.viewers) {
      await this.buildTask;
      for (let viewer of this.viewers) {
        await viewer.setDefaultChartUpdated(viewerType, originalLayoutId, result);
      }
    }
  }

  /**
   * Builds the navigation station viewers. Waits for any existing build task.
   * @returns A promise that resolves when the build is complete.
   */
  public build(): Promise<void> {
    this.buildTask = this.buildInner();
    this.unitsChangedSubscription.add(this.siteHooks.unitsChangedEvent.pipe(debounceTime(RELOAD_DEBOUNCE_TIME)).subscribe(() => this.reload()));
    return this.buildTask;
  }

  /**
   * Builds the navigation station viewers.
   */
  private async buildInner() {

    if (!this.studyJobs || !this.studyJobs.length) {
      return;
    }

    // Get the NavigationStationConfig for this page.
    this.config = await this.configBuilder.build();

    let element = document.getElementById(this.elementId);
    if (!element) {
      return;
    }

    // Create the GridStack instance.
    element.classList.add('grid-stack');

    // We use only the necessary options for sizing. The setup of draggable/resizable is done later.
    this.grid = GridStack.init({
      alwaysShowResizeHandle: false,
      handleClass: 'cs-grid-stack-drag-area',
      resizable: {
        handles: 'e, se, s, sw, w'
      },
      margin: '0.75rem',
      cellHeight: '80px',
      animate: false
    }, this.elementId);

    // Create the viewer instances and their wrappers.
    let viewers: { viewConfig: NavigationStationConfigView; viewer: ViewerWrapper }[] = [];
    for (let view of this.config.views) {
      if (view.viewer) {
        viewers.push({
          viewConfig: view,
          viewer:
            new GridStackViewerWrapper(
              new ButtonsViewerWrapper(
                this,
                this.siteHooks,
                view.layout ? view.layout.requestedLayoutId : undefined,
                view.viewer),
                this.grid)
        });
      }
    }

    // Initialize each viewer.
    let viewIndex = 0;
    for (let v of viewers) {
      if (!this.isPageStillLoaded()) {
        return;
      }

      let view = v.viewConfig;
      let viewer = v.viewer;
      await viewer.initialize(this.elementId, viewIndex, view);
      ++viewIndex;
    }

    // Yield.
    await Timer.delay(0);

    // Build each viewer.
    viewIndex = 0;
    for (let v of viewers) {

      if (!this.isPageStillLoaded()) {
        return;
      }

      let view = v.viewConfig;
      let viewer = v.viewer;
      await viewer.build(this.elementId, viewIndex, view);
      ++viewIndex;
    }

    if (!this.isPageStillLoaded()) {
      return;
    }

    this.grid.on('resizestop', async (_: Event, node: GridItemHTMLElement) => {
      await this.viewers.filter(v => v instanceof GridStackViewerWrapper)
      .map(v => v as GridStackViewerWrapper)
      .find(v=>v.node === node).setSizeUpdated(new Size(node.gridstackNode.w, node.gridstackNode.h));
      await this.redraw();
    });

    this.viewers = viewers.map(v => v.viewer);
  }

  /**
   * Returns whether the page is still loaded.
   */
  private isPageStillLoaded(): boolean {
    return !!document.getElementById(this.elementId);
  }
}
