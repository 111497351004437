import {OnInit, OnDestroy} from '@angular/core';
import {Component} from '@angular/core';
import {
  StudyStub, GetStudyQueryResult,
  GetSupportSessionQueryResult, StudyType
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {Dayjs} from '../../../common/dayjs.service';
import {STUDY_ID_URL_PARAMETER_KEY} from '../../../common/constants';
import {CanopyJson} from '../../../common/canopy-json.service';
import {VisualizationFactory} from '../../visualizations/visualization-factory.service';
import {Timer} from '../../../common/timer.service';
import {CanopyPusher, StudiesProgress, StudyBuildProgress} from '../../../common/canopy-pusher.service';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {Disposable} from '../../../common/disposable';
import {ActivatedRoute} from '@angular/router';
import {StudyTypeLookup, StudyTypeMap} from '../study-type-lookup.service';
import {CreateUserInformationMaps} from '../../create-user-information-maps.service';
import {StageStudy} from '../stage-study.service';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {Subscription} from 'rxjs';
import {CreateWorksheetFromStudyIfRequired} from '../../../worksheets/create-worksheet-from-study-if-required';
import {CreateWorksheetRowFromStudyOptions} from '../../../worksheets/create-worksheet-row-from-study';
import {IsWorksheetPage} from '../../is-worksheet-page';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Component({
  templateUrl: './view-study.page.html',
  styleUrls: ['./view-study.page.scss']
})
export class ViewStudyPage implements OnInit, OnDestroy {

  public errorMessage: string;
  public tenantId: string;
  public userId: string;
  public studyId: string;
  public isOwner: boolean;
  public isCurrentTenant: boolean;
  public simVersion: string;
  public isWorksheetPage: boolean;
  public priority: number;

  public isLoaded: boolean;
  public isLoadingChart: boolean;

  public study: StudySummary;
  public studyJson: any;

  public studiesProgressSubscription: Subscription;
  public studyBuildProgressSubscription: Subscription;
  public loadTask: Promise<any>;
  public studyTypeMap: StudyTypeMap;

  public viewer: Disposable;

  public studyResultTask: Promise<GetStudyQueryResult>;

  constructor(
    isWorksheetPage: IsWorksheetPage,
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly studyStub: StudyStub,
    private readonly json: CanopyJson,
    private readonly stageStudy: StageStudy,
    private readonly visualizationFactory: VisualizationFactory,
    private readonly dayjs: Dayjs,
    private readonly timer: Timer,
    private readonly canopyPusher: CanopyPusher,
    private readonly authenticationService: AuthenticationService,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    public readonly significantSimVersions: SignificantSimVersions,
    private readonly getSimVersion: GetSimVersion,
    private readonly createWorksheetFromStudyIfRequired: CreateWorksheetFromStudyIfRequired,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){

    this.isWorksheetPage = isWorksheetPage.execute(route);
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);
    this.studyId = route.snapshot.params[STUDY_ID_URL_PARAMETER_KEY];
  }

  public ngOnInit() {
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.studyBuildProgressSubscription = this.canopyPusher.studyBuildProgress.subscribe((data: StudyBuildProgress) => this.onStudyBuildProgress(data));
    this.loadTask = this.load();
  }

  public ngOnDestroy() {
    if (this.studiesProgressSubscription) {
      this.studiesProgressSubscription.unsubscribe();
    }
    if (this.studyBuildProgressSubscription) {
      this.studyBuildProgressSubscription.unsubscribe();
    }
    if(this.viewer){
      this.viewer.dispose();
    }
  }

  public get parentWorksheetId(): string | undefined {
    return this.study ? this.study.studyResult.study.parentWorksheetId : undefined;
  }

  public get runningText(): string {
    let ijc = this.study.incompleteJobCount;
    return ijc ? `${ijc} ${ijc === 1 ? 'job is' : 'jobs are'} still running.` : 'Running';
  }

  public async load() {
    try {
      this.isLoaded = false;

      const userData = this.authenticationService.userDataSnapshot;
      this.isOwner = userData.sub === this.userId;
      this.isCurrentTenant = userData.tenant === this.tenantId;

      this.studyResultTask = this.studyStub.getStudy(this.tenantId, this.studyId, this.getSimVersion.currentSimVersion);
      let studyResult = await this.studyResultTask;
      let map = this.createUserInformationMaps.execute(studyResult.userInformation);
      let username = map.users[studyResult.study.userId].username;

      this.studyTypeMap = await this.studyTypeLookup.getStudyTypeMap();

      this.applyLoadedStudy(studyResult, username);

      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    await this.showChart();
  }

  public applyLoadedStudy(studyResult: GetStudyQueryResult, username: string){
    this.simVersion = studyResult.convertedSimVersion;
    this.priority = studyResult.priority;
    let s = studyResult.study;
    this.studyJson = this.json.stringify(s);

    let jobCount = s.data.jobCount || s.data.dispatchedJobCount; // Backwards compatibility.
    let hasExplorationMap = jobCount > 1
      && !!studyResult.study.data.definition.exploration;

    let isCompleted = jobCount && jobCount === s.data.completedJobCount;
    let hasErrors = !!(s.data.errorMessages && s.data.errorMessages.length);
    let isBuilding = !isCompleted && !hasErrors && (!jobCount || jobCount !== s.data.dispatchedJobCount);

    this.study = new StudySummary(
      s.name,
      username,
      this.dayjs.fromNow(s.creationDate),
      jobCount,
      s.data.dispatchedJobCount,
      s.data.succeededJobCount,
      s.data.completedJobCount - s.data.succeededJobCount,
      s.data.dispatchedJobCount - s.data.completedJobCount,
      s.data.succeededComputeCredits,
      s.data.succeededStorageCredits,
      isCompleted,
      isBuilding,
      hasExplorationMap,
      hasExplorationMap ? studyResult.study.data.definition.exploration.design.name : undefined,
      CustomPropertyUtilities.objectToList(s.properties),
      s.notes,
      s.data.studyType,
      this.studyTypeMap[s.data.studyType].name,
      studyResult,
      this.unique(s.data.errorMessages));


  }

  private unique(input: any[]): any[]{
    let result = [];
    if(input){
      for(let item of input){
        if(result.indexOf(item) === -1){
          result.push(item);
        }
      }
    }
    return result;
  }

  public async showChart(){
    try {
      if(this.study){
        this.study.showExplorationMapViewer =
          this.study.hasExplorationMap
          && this.study.isCompleted
          && this.study.succeededJobCount > 0;

        if(this.study.showExplorationMapViewer){
          this.isLoadingChart = true;
          await this.timer.yield();
          await this.plotViewer();
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    this.isLoadingChart = false;
  }

  public async plotViewer(){
    let accessInformation = this.study.studyResult.accessInformation;

    if(this.viewer){
      this.viewer.dispose();
    }

    if(this.study.explorationDesignType === 'Star'){
      let viewer = this.visualizationFactory.createStarExplorationMapViewer(
        'dashboard',
        accessInformation,
        this.tenantId,
        this.studyId,
        this.study.name,
        this.study.studyResult.simTypes);
      this.viewer = viewer;
      await viewer.build();
    } else{
      let viewer = this.visualizationFactory.createParallelCoordinatesViewer(
        'dashboard',
        accessInformation,
        this.tenantId,
        this.studyId,
        this.study.name,
        this.study.studyResult.simTypes);
      this.viewer = viewer;
      await viewer.build();
    }
  }

  public stageToResultsStagingArea() {
    try {
      this.stageStudy.toResultsStagingArea(
        this.tenantId, this.studyId, this.study.name, this.study.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async stageToStudyStagingArea() {
    try {
      await this.stageStudy.toStudyStagingArea(
        this.tenantId, this.studyId, this.study.name, this.study.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.study || this.study.isCompleted){
        return;
      }

      for(let item of data.items) {

        if(item.studyId !== this.studyId){
          continue;
        }

        this.study.succeededJobCount = item.succeededJobCount;
        this.study.succeededComputeCredits = item.succeededComputeCredits;
        this.study.succeededStorageCredits = item.succeededStorageCredits;
        this.study.failedJobCount = item.completedJobCount - item.succeededJobCount;
        this.study.incompleteJobCount = this.study.jobCount - item.completedJobCount;
        this.study.isCompleted = this.study.jobCount && this.study.jobCount === item.completedJobCount;
        this.study.creationDate = this.dayjs.fromNow(this.study.studyResult.study.creationDate);
      }

      if(this.study.isCompleted){
        let currentUsername = this.study.username;
        this.studyResultTask = this.studyStub.getStudy(this.tenantId, this.studyId, this.getSimVersion.currentSimVersion);
        let studyResult = await this.studyResultTask;
        this.applyLoadedStudy(studyResult, currentUsername);
        await this.showChart();
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onStudyBuildProgress(data: StudyBuildProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.study || this.study.isCompleted){
        return;
      }

      if(data.studyId !== this.studyId || this.study.dispatchedJobCount > data.dispatchedJobCount){
        return;
      }

      if(data.errorMessages){
        this.study.errorMessages = data.errorMessages;
        this.study.isBuilding = false;
        this.study.isCompleted = true;
        return;
      }

      this.study.jobCount = data.jobCount;
      this.study.dispatchedJobCount = data.dispatchedJobCount;
      this.study.isBuilding = data.dispatchedJobCount !== data.jobCount;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get supportSession(): GetSupportSessionQueryResult{
    return {
      session: this.study.studyResult.study.supportSession,
      userInformation: this.study.studyResult.userInformation
    };
  }
  public onSupportSessionChanged(supportSession: GetSupportSessionQueryResult){
    this.study.studyResult.userInformation = supportSession.userInformation;
    this.study.studyResult.study.supportSession = supportSession.session;
  }

  public get showWorksheets(): boolean {
    return this.authenticationService.showWorksheets;
  }

  public async createWorksheet(event: MouseEvent){
    await this.createWorksheetFromStudyIfRequired.execute(
      this.tenantId,
      this.studyId,
      this.study.name,
      false,
      CreateWorksheetRowFromStudyOptions.fromEvent(event));
  }
}

export class StudySummary {
  constructor(
    public name: string,
    public username: string,
    public creationDate: string,
    public jobCount: number,
    public dispatchedJobCount: number,
    public succeededJobCount: number,
    public failedJobCount: number,
    public incompleteJobCount: number,
    public succeededComputeCredits: number,
    public succeededStorageCredits: number,
    public isCompleted: boolean,
    public isBuilding: boolean,
    public hasExplorationMap: boolean,
    public explorationDesignType: string,
    public properties: CustomProperty[],
    public notes: string,
    public studyType: StudyType,
    public studyTypeName: string,
    public studyResult: GetStudyQueryResult,
    public errorMessages: string[]
  ){}

  public showExplorationMapViewer: boolean;
}
