import { Injectable } from '@angular/core';
import { AllButtonCategories } from '../json-editor-node-extensions/editor-node-extensions';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class MultipleEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.multiple = class extends JSONEditor.defaults.editors.multiple{
      // Base implementation considers an element having identical title and description as a duplicate, and ends up appending' 1'
      // Base implementation will try and use format, type and description if no title, but we just want title.
      // Both these issues are fixed here
      getDisplayText(arr: any[]) {
        return arr.map((x: any) => x.title);
      }

      isHiddenSwitcher(): boolean {
        let switcher = this.switcher as HTMLSelectElement;
        let itemCount = this.display_text.length;
        return itemCount === 1
          || (itemCount === 2 && (this.display_text[0] === 'Encrypted' || this.display_text[1] === 'Encrypted'))
          || switcher.value === 'Encrypted'
      }

      updateSwitcherVisibility() {
        let switcher = this.switcher as HTMLSelectElement;
        if (this.isHiddenSwitcher()) {
          switcher.classList.add('select-hidden');
        } else {
          switcher.classList.remove('select-hidden');
        }
      }

      // Fixes issue where enable is called on adding a property before the switcher is ready
      // Called because the collapse button is clicked on object build() if required to collapse the editor
      // but this causes a callback to fire when a click happens outside the add_proeprty modal which eventually calls enable()
      enable () {
        if(this.switcher !== undefined){
          super.enable()
        }
      }

      build() {
        super.build();
        const canopyOptions = getCanopyJsonEditorOptions(this);
        if (this.container.children.length < 3) {
          return;
        }

        const header = document.createElement('div');
        const hoverArea = document.createElement('div');
        this.container.insertBefore(hoverArea, this.container.children[0]);
        hoverArea.appendChild(header);
        header.appendChild(this.container.children[1]);
        header.appendChild(this.container.children[1]);

        this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
        let buttonsResult = this.extensions.addButtonsContainer(header, AllButtonCategories);

        if (buttonsResult) {
          hoverArea.className += ' hover-button-group-container';

          let oldSwitcher = this.switcher;
          //remove eventlisteners
          this.switcher = this.switcher.cloneNode(true);
          this.container.removeChild(oldSwitcher);
          header.insertBefore(this.switcher, header.lastChild);
          this.switcher.addEventListener('change', (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            let value = e.currentTarget.value;

            if (value === 'Encrypted') {
              buttonsResult.buttons.encrypt.click();
            } else {
              this.switchEditor(this.display_text.indexOf(value));
              this.onChange(true);
            }

            this.updateSwitcherVisibility();
          });
        }
      }

      onChange(bubble: boolean) {
        super.onChange(bubble);
        this.updateSwitcherVisibility();
      }

      setValue(val: any, initial: any) {
        if(val === undefined || JSON.stringify(this.prevVal) === JSON.stringify(val)) return;
        this.prevVal = val;
        let prevType = this.type;
        if(val.name){
          for(let [i, type] of this.types.entries()){
            if(type.properties?.name?.enum){
              if(type.properties.name.enum[0] === val.name){
                this.type = i;
                this.switcher.value = this.display_text[i]

                const typeChanged = (this.type !== prevType)

                if (typeChanged) {
                  this.switchEditor(this.type)
                }

                this.editors[this.type].setValue(val, initial)

                this.refreshValue()
                this.onChange(typeChanged)

                if (this.extensions) {
                  this.extensions.update();
                }

                return;
              }
            }
          }
        }

        super.setValue(val, initial);

        if (this.extensions) {
          this.extensions.update();
        }
      }

      showValidationErrors (errors: any) {
        /* oneOf and anyOf error paths need to remove the oneOf[i] part before passing to child editors */
        if (this.oneOf || this.anyOf) {
          const checkPart = this.oneOf ? 'oneOf' : 'anyOf'
          this.editors.forEach((editor: any, i: any) => {
            if (!editor) return
            const check = `${this.path}.${checkPart}[${i}]`
            const filterError = (newErrors: any, error: any) => {
              if (error.path.startsWith(check) || (error.path === check.substring(0, error.path.length)) && !this.isHiddenSwitcher()) {
                const newError = structuredClone(error);

                if (error.path.startsWith(check)) {
                  newError.path = this.path + newError.path.substr(check.length)
                }
                newErrors.push(newError)
              }
              return newErrors
            }
            editor.showValidationErrors(errors.reduce(filterError, []))
          })
        } else {
          this.editors.forEach((editor: any) => {
            if (!editor) return
            editor.showValidationErrors(errors)
          })
        }
      }
    }
  }
}
