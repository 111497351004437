import {
  NavigationStationViewer, IEditChannelsType,
} from './navigation-station-viewer';
import { ViewerWrapper } from './viewer-wrapper';
import { SiteHooks, LoadViewerLayoutResult, RequestedLayoutIds, ResolvedLayoutId, ViewerMetadata } from '../site-hooks';
import { INavigationStationCallbacks } from './navigation-station-callbacks';
import { cssSanitize } from '../css-sanitize';
import { NavigationStationConfigView } from './config-builders/navigation-station-config-builder';
import { CanStackDiagonals } from '../viewers/data-pipeline/can-stack-diagonals';
import { ISize } from '../viewers/size';

/**
 * A wrapper for a viewer that adds buttons for loading, saving, and editing the viewer.
 * Note this doesn't use Angular because the visualizations were previously in a separate
 * non-Angular repository.
 */
export class ButtonsViewerWrapper implements ViewerWrapper {

  // The type of viewer being wrapped.
  private viewerType?: string;

  // The ID associated with this chart.
  private originalViewerId?: RequestedLayoutIds;

  // The default viewer ID loaded when the chart was loaded.
  private defaultViewerId: ResolvedLayoutId;

  // The current viewer ID loaded into the chart.
  private viewerId: ResolvedLayoutId;

  // The name of the viewer.
  private viewerName: any;

  // The list of different ways the viewer can be edited. This is used to create the edit buttons.
  private editTypes: IEditChannelsType[] = [];

  // Various button and element IDs.
  private errorElementId?: string;
  private editButtonIds: string[] = [];
  private loadButtonId?: string;
  private saveButtonId?: string;
  private saveAsButtonId?: string;
  private setDefaultButtonId?: string;
  private importButtonId?: string;
  private importFileInputId?: string;
  private exportButtonId?: string;
  private stackDiagonalsHorizontallyButtonId?: string;
  private stackDiagonalsVerticallyButtonId?: string;
  private subTitleId?: string;
  private buttonsId?: string;
  private buttonsToggleId?: string;
  private buttonsToggleTargetId?: string;
  private loadingId?: string;

  // Whether the viewer can be saved.
  private canSave: boolean = false;

  // Whether the viewer can have a custom layout set as default.
  private canSetDefault: boolean = false;

  // The IDs of all the buttons.
  private buttonIds: string[] = [];

  /**
   * Creates a new instance of ButtonsViewerWrapper.
   * @param callbacks Callbacks into NavigationStation.
   * @param siteHooks The site hooks.
   * @param requestedLayoutIds The requested layout IDs.
   * @param viewer The viewer to wrap.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simTypes The simulation types.
   * @param channelNameStyle The channel name style.
   */
  constructor(
    private callbacks: INavigationStationCallbacks,
    private siteHooks: SiteHooks,
    private requestedLayoutIds: RequestedLayoutIds | undefined,
    private viewer: NavigationStationViewer) {
  }

  /**
   * Reloads the viewer. This occurs when, for example, the user updates
   * their custom units.
   * @returns A promise that resolves when the viewer has been reloaded.
   */
  public async reload(): Promise<void> {
    // Read the current layout JSON of the viewer.
    let layout = this.viewer.getLayout();
    if (layout) {
      this.setAllButtonsDisabled(true);
      try {
        // Set the same layout back in again.
        await this.viewer.setLayout(layout);
        this.renderButtonActiveStates(layout);
      } finally {
        this.setAllButtonsDisabled(false);
      }
    }
  }

  /**
   * Disposes of the viewer.
   */
  public dispose() {
    return this.viewer.dispose();
  }

  /**
   * Called when a default chart has been updated by the user.
   * @param viewerType The viewer type.
   * @param resultOriginalViewerId The original viewer ID.
   * @param result The loaded viewer layout.
   */
  public async setDefaultChartUpdated(viewerType: string, resultOriginalViewerId: RequestedLayoutIds, result: LoadViewerLayoutResult) {

    // If our viewer type doesn't match, return.
    if (this.viewerType !== viewerType) {
      return;
    }

    // If we don't have an original viewer ID, return.
    if (!this.originalViewerId) {
      return;
    }

    // If the original viewer ID doesn't match the provided layout IDs, return.
    if (!this.compareViewerIds(this.originalViewerId, resultOriginalViewerId)) {
      return;
    }

    // This applies to us, so update.
    this.clearError();
    try {
      this.defaultViewerId = result.layoutId;
      await this.updateViewerId(result.layoutId, result.name);
      this.setAllButtonsDisabled(true);
      let layout = result.getConfigCopy();
      await this.viewer.setLayout(layout);
      this.renderButtonActiveStates(layout);
    } catch (error) {
      this.setError(error);
    } finally {
      this.setAllButtonsDisabled(false);
    }
  }

  /**
   * Initializes the viewer buttons.
   * @param elementId The element ID we're targeting.
   * @param viewIndex The view index.
   * @param viewConfig The view configuration.
   */
  public async initialize(elementId: string, viewIndex: number, viewConfig: NavigationStationConfigView): Promise<void> {
    this.viewerType = viewConfig.viewerType;

    let innerElementId = elementId + '-view';

    this.editTypes = this.viewer.getEditChannelsTypes() || [{ name: 'Edit', id: undefined }];
    this.editButtonIds = this.editTypes.map(v => elementId + '-edit-button' + (v.id ? '-' + cssSanitize(v.id) : ''));

    this.loadButtonId = elementId + '-load-button';
    this.saveButtonId = elementId + '-save-button';
    this.saveAsButtonId = elementId + '-save-as-button';
    this.setDefaultButtonId = elementId + '-set-default-button';
    this.importButtonId = elementId + '-import-button';
    this.importFileInputId = elementId + '-import-file-input';
    this.exportButtonId = elementId + '-export-button';

    this.stackDiagonalsHorizontallyButtonId = elementId + '-share-row-axis-button';
    this.stackDiagonalsVerticallyButtonId = elementId + '-share-column-axis-button';

    this.errorElementId = elementId + '-error';

    this.subTitleId = elementId + '-sub-title';
    this.buttonsId = elementId + '-buttons';
    this.buttonsToggleId = elementId + '-buttons-toggle';
    this.buttonsToggleTargetId = elementId + '-buttons-toggle-target';
    this.loadingId = elementId + '-loading';

    this.buttonIds = [
      ...this.editButtonIds,
      this.loadButtonId,
      this.saveButtonId,
      this.saveAsButtonId,
      this.setDefaultButtonId,
      this.importButtonId,
      this.exportButtonId,
      this.stackDiagonalsHorizontallyButtonId,
      this.stackDiagonalsVerticallyButtonId,
    ];
    let viewerTitle = document.getElementById(elementId + '-viewer-title')
    viewerTitle.insertAdjacentHTML('beforeend', `<button type="button" class="btn btn-secondary button-set-default hidden-button" id="${this.setDefaultButtonId}">Set Default</button>`);
    let cardElement = document.getElementById(elementId + '-card');
    cardElement.insertAdjacentHTML('afterbegin', `
      <div class="alert alert-danger viewer-error hidden-error" id="${this.errorElementId}">
      </div>
      <div class="viewer-content">
        <div id="${this.loadingId}" class="loading-indicator"><p>Loading...</p></div>
        <div id="${innerElementId}">
        </div>
      </div>
      <div class="card-buttons card-buttons-hidden" id="${this.buttonsId}">
        <div class="card-buttons-toggle" id="${this.buttonsToggleId}">
          <i class="fa fa-bars"></i>
        </div>
        <div class="card-buttons-toggle-target" id="${this.buttonsToggleTargetId}">
          <div class="button-row">
            <button type="button" class="btn btn-secondary button-load" id="${this.loadButtonId}" disabled>Load
            </button><button type="button" class="btn btn-secondary button-save" id="${this.saveButtonId}" disabled>Save
            </button><button type="button" class="btn btn-secondary button-save-as" id="${this.saveAsButtonId}" disabled>Save As
            </button><button type="button" class="btn btn-secondary button-import hidden-button" id="${this.importButtonId}" disabled>Import
            </button><input type="file" style="display: none;" id="${this.importFileInputId}" /><button type="button" class="btn btn-secondary button-export hidden-button" id="${this.exportButtonId}" disabled>Export
            </button>
          </div>
          <div class="button-row">
            <button type="button" class="btn btn-secondary button-import hidden-button" id="${this.stackDiagonalsHorizontallyButtonId}" disabled>Stack Diagonals Horizontally
            </button><button type="button" class="btn btn-secondary button-import hidden-button" id="${this.stackDiagonalsVerticallyButtonId}" disabled>Stack Diagonals Vertically
            </button>
          </div>
        </div>
      </div>
    `);

    for (let i = 0; i < this.editButtonIds.length; ++i) {
      let editButtonId = this.editButtonIds[i];
      let editOption = this.editTypes[i];
      let editButton = new DOMParser().parseFromString(
        `<button type="button" class="btn btn-secondary button-edit" id="${editButtonId}" disabled>
          ${editOption.icon ? '<i class="fa fa-' + editOption.icon + '"></i>' : ''}${editOption.name}
        </button>`, 'text/html').getElementById(editButtonId);
      document.getElementById(this.loadButtonId).before(editButton);

      document.getElementById(editButtonId).addEventListener('click', () => this.editViewerClickHandler(editOption));
    }

    document.getElementById(this.loadButtonId).addEventListener('click', () => this.loadViewerClickHandler());
    document.getElementById(this.saveButtonId).addEventListener('click', ()  => this.saveViewerClickHandler());
    document.getElementById(this.saveAsButtonId).addEventListener('click', () => this.saveViewerAsClickHandler());
    document.getElementById(this.setDefaultButtonId).addEventListener('click', () => this.setDefaultClickHandler());
    document.getElementById(this.importButtonId).addEventListener('click', () => this.importDataClickHandler());
    document.getElementById(this.importFileInputId).addEventListener('change', () => this.onImportDataClickHandler());
    document.getElementById(this.exportButtonId).addEventListener('click', ()  => this.exportDataClickHandler());
    document.getElementById(this.stackDiagonalsHorizontallyButtonId).addEventListener('click', () => this.stackDiagonalsHorizontallyClickHandler());
    document.getElementById(this.stackDiagonalsVerticallyButtonId).addEventListener('click', () => this.stackDiagonalsVerticallyClickHandler());

    document.getElementById(this.buttonsId).addEventListener('mouseenter', e => {
        let element = (e.target as HTMLElement);
        element.classList.remove('card-buttons-hidden');
        element.classList.add('card-buttons-shown');
      });
    document.getElementById(this.buttonsId).addEventListener('mouseleave', e => {
        let element = (e.target as HTMLElement);
        element.classList.remove('card-buttons-shown');
        element.classList.add('card-buttons-hidden');
      });

    if (viewConfig.layout && viewConfig.layout.requestedLayoutId) {
      this.originalViewerId = viewConfig.layout.requestedLayoutId;
      this.defaultViewerId = viewConfig.layout.defaultViewerId;
      await this.updateViewerId(viewConfig.layout.resolvedLayout.layoutId, viewConfig.layout.resolvedLayout.name);
    } else {
      await this.updateViewerId(undefined, undefined);
    }

    this.viewer.errorEvent.subscribe((message: string) => this.setErrorMessage(message));
  }

  /**
   * Build the inner viewer and update button states.
   * @param elementId The element ID we're targeting.
   * @param viewIndex The view index.
   * @param viewConfig The view configuration.
   * @returns A promise that resolves when the viewer has been built.
   */
  public async build(elementId: string, viewIndex: number, viewConfig: NavigationStationConfigView): Promise<void> {

    let innerElementId = elementId + '-view';
    let isPageStillLoaded = false;
    try {
      await this.viewer.build(innerElementId);
    } catch (error) {
      this.setError(error);
    } finally {
      let loadingElement = document.getElementById(this.loadingId);
      if(loadingElement){
        loadingElement.remove();
        isPageStillLoaded = true;
      }
    }

    if(!isPageStillLoaded){
      return;
    }

    this.setAllButtonsDisabled(false);

    if (this.viewer.canImportData && this.viewer.canImportData()) {
      document.getElementById(this.importButtonId).classList.remove('hidden-button');
    }

    if (this.viewer.canExportData && this.viewer.canExportData()) {
      document.getElementById(this.exportButtonId).classList.remove('hidden-button');
    }

    let layout = this.viewer.getLayout();
    this.renderButtonActiveStates(layout);
  }

  /**
   * Sets all the buttons to be disabled or not.
   * @param isDisabled Whether the buttons should be disabled.
   */
  private setAllButtonsDisabled(isDisabled: boolean) {
    for (let id of this.buttonIds) {
      if(isDisabled){
        document.getElementById(id).setAttribute('disabled', '');
      }else{
        document.getElementById(id).removeAttribute('disabled');
      }

    }

    if (!isDisabled) {
      this.renderButtonDisabledStates();
    }
  }

  /**
   * Clears the error message.
   */
  private clearError() {
    let element = document.getElementById(this.errorElementId);
    element.classList.add('hidden-error');
    element.replaceChildren();
  }

  /**
   * Sets an error object.
   * @param error The error object.
   */
  private setError(error: any) {
    let message = this.siteHooks.getFriendlyErrorAndLog(error);
    this.setErrorMessage(message);
  }

  /**
   * Sets an error message.
   * @param message The error message.
   */
  private setErrorMessage(message: string) {
    let element = document.getElementById(this.errorElementId);
    element.replaceChildren();
    element.append(`<p>${message}</p>`);
    element.classList.remove('hidden-error');
  }

  /**
   * Updates the button disabled states and renders them.
   * @returns A promise that resolves when the button states have been updated.
   */
  private async updateButtonDisabledStates() {
    if (!this.viewerType) {
      return;
    }

    this.canSave = await this.siteHooks.canSaveViewerLayout(this.viewerType, this.viewerId);
    this.canSetDefault = !!(this.originalViewerId && !this.compareViewerIds(this.defaultViewerId, this.viewerId));

    this.renderButtonDisabledStates();
  }

  /**
   * Renders the active states of the buttons.
   * @param layout The layout.
   */
  private renderButtonActiveStates(layout: any) {

    let canStackDiagonals = layout
      && layout && layout.columns && layout.rows
      && CanStackDiagonals.execute(layout.rows.length, layout.columns.length);

    if (canStackDiagonals && this.viewer.canStackDiagonals && this.viewer.canStackDiagonals()) {
      document.getElementById(this.stackDiagonalsHorizontallyButtonId).classList.remove('hidden-button');
      document.getElementById(this.stackDiagonalsVerticallyButtonId).classList.remove('hidden-button');
    } else {
      document.getElementById(this.stackDiagonalsHorizontallyButtonId).classList.add('hidden-button');
      document.getElementById(this.stackDiagonalsVerticallyButtonId).classList.add('hidden-button');
    }

    if (layout && layout.stackDiagonalsVertically) {
      document.getElementById(this.stackDiagonalsVerticallyButtonId).classList.add('active-button');
    } else {
      document.getElementById(this.stackDiagonalsVerticallyButtonId).classList.remove('active-button');
    }
    if (layout && layout.stackDiagonalsHorizontally) {
      document.getElementById(this.stackDiagonalsHorizontallyButtonId).classList.add('active-button');
    } else {
      document.getElementById(this.stackDiagonalsHorizontallyButtonId).classList.remove('active-button');
    }
  }

  /**
   * Renders the disabled states of the buttons.
   */
  private renderButtonDisabledStates() {
    let saveAsButton = document.getElementById(this.saveButtonId);
    if(this.canSave){
      saveAsButton.removeAttribute('disabled');
    }else{
      saveAsButton.setAttribute('disabled', '');
    }
    saveAsButton.innerHTML = 'Save';

    //$('#' + this.setDefaultButtonId).prop('disabled', !this.canSetDefault);
    if (this.canSetDefault) {
      document.getElementById(this.setDefaultButtonId).classList.remove('hidden-button');
    } else {
      document.getElementById(this.setDefaultButtonId).classList.add('hidden-button');
    }
  }

  /**
   * Updates the viewer ID of the viewer.
   * @param newViewerId The new viewer ID.
   * @param newViewerName The new viewer name.
   */
  private async updateViewerId(newViewerId: any, newViewerName: string | undefined) {
    this.viewerId = newViewerId;
    this.viewerName = newViewerName;
    if(document.getElementById(this.subTitleId)){
      document.getElementById(this.subTitleId).innerText = newViewerName || '';
    }

    await this.updateButtonDisabledStates();
  }

  /**
   * Handles the "Edit Viewer" button click event. This calls into siteHooks to allow the user to edit
   * the channels, the layout, etc.
   * @param editChannelsType The type of edit to perform.
   * @returns A promise that resolves when the viewer has been edited.
   */
  private async editViewerClickHandler(editChannelsType: IEditChannelsType) {
    if (!this.viewerType) {
      return;
    }

    this.clearError();
    try {
      let editChannelsOptions = await this.viewer.getEditChannelsOptions(editChannelsType);
      if (editChannelsOptions) {
        let result = await this.siteHooks.editViewerChannels(editChannelsOptions);
        if (result) {
          this.setAllButtonsDisabled(true);
          await this.viewer.setEditChannelsResult(editChannelsType, result);
          let layout = this.viewer.getLayout();
          this.renderButtonActiveStates(layout);
        }
      }
    } catch (error) {
      this.setError(error);
    } finally {
      this.setAllButtonsDisabled(false);
    }
  }

  /**
   * Handles the "Load Viewer" click event. This calls into siteHooks to display a load dialog.
   * @returns A promise that resolves when the viewer has been loaded.
   */
  private async loadViewerClickHandler() {
    if (!this.viewerType || !this.originalViewerId) {
      return;
    }

    this.clearError();
    try {
      let result = await this.siteHooks.loadViewerLayout(this.viewerType);
      if (result) {
        let layoutId = result.layoutId;
        let name = result.name;
        if (this.originalViewerId.fallback.indexOf(layoutId) !== -1) {
          layoutId = this.originalViewerId.primary;
          name = this.originalViewerId.primary;
        }
        await this.updateViewerId(layoutId, name);
        this.setAllButtonsDisabled(true);
        let layout = result.getConfigCopy();
        await this.viewer.setLayout(layout);
        this.renderButtonActiveStates(layout);
      }
    } catch (error) {
      this.setError(error);
    } finally {
      this.setAllButtonsDisabled(false);
    }
  }

  /**
   * Handles the "Save Viewer" click event. This calls into siteHooks to save the viewer.
   * @returns A promise that resolves when the viewer has been saved.
   */
  private async saveViewerClickHandler() {
    if (!this.viewerType) {
      return;
    }

    this.clearError();
    try {
      if (this.viewerName && this.viewerId) {
        this.canSave = await this.siteHooks.canSaveViewerLayout(this.viewerType, this.viewerId);
        if (this.canSave) {
          let saveButton = document.getElementById(this.saveButtonId);
          saveButton.setAttribute('disabled', '');
          saveButton.innerHTML = 'Saving';
          let layout = this.viewer.getLayout();
          this.setAllButtonsDisabled(true);
          await this.siteHooks.saveViewerLayout(this.viewerType, this.viewerId, this.viewerName, layout);
        }
      }
    } catch (error) {
      this.setError(error);
    } finally {
      this.setAllButtonsDisabled(false);
    }

    await this.updateButtonDisabledStates();
  }

  /**
   * Handles the "Save Viewer As" click event. This calls into siteHooks to present a Save As dialog.
   * @returns A promise that resolves when the viewer has been saved.
   */
  private async saveViewerAsClickHandler() {
    if (!this.viewerType) {
      return;
    }

    this.clearError();
    try {
      let layout = this.viewer.getLayout();
      let result = await this.siteHooks.saveViewerLayoutAs(this.viewerType, layout);
      if (result) {
        await this.updateViewerId(result.viewerId, result.name);
      }
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Handles the "Set Default" click event. This calls into siteHooks to set the default layout.
   * @returns A promise that resolves when the default layout has been set.
   */
  private async setDefaultClickHandler() {
    if (!this.viewerType) {
      return;
    }

    await this.saveViewerClickHandler();

    this.clearError();
    try {
      if (this.originalViewerId) {
        this.setAllButtonsDisabled(true);
        this.defaultViewerId = this.viewerId;
        await this.siteHooks.setViewerDefaultLayoutId(this.viewerType, this.originalViewerId, this.viewerId);
        await this.callbacks.setDefaultChartUpdated(this.viewerType, this.originalViewerId, new LoadViewerLayoutResult(
          this.viewerId,
          this.viewerName,
          this.viewer.getLayout()));
      }
    } catch (error) {
      this.setError(error);
    } finally {
      this.setAllButtonsDisabled(false);
    }

    await this.updateButtonDisabledStates();
  }

  /**
   * Handles the "Import Data" click event. This calls into siteHooks to display the import dialog.
   * @returns A promise that resolves when the data has been imported.
   */
  private async importDataClickHandler() {
    if (!this.importFileInputId) {
      return;
    }

    this.clearError();
    try {
      document.getElementById(this.importFileInputId).click();
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Handles a file being set on the import data UI element.
   * @returns A promise that resolves when the data has been imported.
   */
  private async onImportDataClickHandler() {
    if (!this.importFileInputId) {
      return;
    }

    this.clearError();
    let input: any | undefined;
    try {
      input = <any>document.getElementById(this.importFileInputId);
      let file = input.files[0];

      if (file) {
        let reader = new FileReader();
        reader.onload = (e: any) => this.loadCsvFile(file.name, e.target.result);
        reader.readAsText(file);
      }
    } catch (error) {
      this.setError(error);
    }

    if (input) {
      input.value = '';
    }
  }

  /**
   * Handles the "Export Data" click event. This calls into the viewer to export the data.
   * @returns A promise that resolves when the data has been exported.
   */
  private async exportDataClickHandler() {
    this.clearError();
    try {
      await this.viewer.exportCsvData();
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Handles the "Stack Diagonals Vertically" click event. This calls into the viewer to stack the diagonals vertically.
   */
  public async stackDiagonalsVerticallyClickHandler() {
    this.clearError();
    try {
      let layout = this.viewer.getLayout();
      layout.stackDiagonalsVertically = !layout.stackDiagonalsVertically;
      await this.viewer.setLayout(layout);
      this.renderButtonActiveStates(layout);
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Handles the "Stack Diagonals Horizontally" click event. This calls into the viewer to stack the diagonals horizontally.
   */
  public async stackDiagonalsHorizontallyClickHandler() {
    this.clearError();
    try {
      let layout = this.viewer.getLayout();
      layout.stackDiagonalsHorizontally = !layout.stackDiagonalsHorizontally;
      await this.viewer.setLayout(layout);
      this.renderButtonActiveStates(layout);
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Sets the size of the viewer.
   * @param size The size of the viewer.
   * @returns A promise that resolves when the size has been updated.
   */
  public async setSizeUpdated(size: ISize): Promise<void> {
    if (!this.viewerType) {
      return;
    }

    await this.siteHooks.saveViewerMetadata(
      this.viewerType,
      this.requestedLayoutIds ? this.requestedLayoutIds.primary : '',
      new ViewerMetadata(size));
  }

  /**
   * Loads a CSV file into the viewer.
   * @param name The name of the file.
   * @param contents The contents of the file.
   */
  private loadCsvFile(name: string, contents: string) {
    this.clearError();
    try {
      this.viewer.importCsvData(name, contents);
    } catch (error) {
      this.setError(error);
    }
  }

  /**
   * Compares two viewer IDs.
   * @param a The first viewer ID.
   * @param b The second viewer ID.
   * @returns Whether the viewer IDs are the same.
   */
  private compareViewerIds(a: ResolvedLayoutId, b: ResolvedLayoutId): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
