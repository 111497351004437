import { NgZone } from '@angular/core';
import { NavigationStation } from '../../visualizations/navigation-station/navigation-station';
import {
  IEditChannelsOptions, LoadViewerLayoutResult,
  PaneChannelLayout,
  RequestedLayoutIds,
  ResolvedLayoutId,
  SiteHooks,
  ViewerMetadata
} from '../../visualizations/site-hooks';
import {
  ChannelBinaryFormat,
  ChannelMetadata,
  SingleScalarResult,
  StudyScalarResults,
  UrlFileLoader,
  StudyMetadata
} from '../../visualizations/url-file-loader';
import { Subject } from 'rxjs';
import { SimType } from '../../../generated/api-stubs';
import { ExplorationMap } from '../../visualizations/viewers/channel-data-loaders/exploration-map';

/**
 * A navigation station that runs outside of the Angular zone.
 */
export class OutOfZoneNavigationStation {

  /**
   * Creates a new instance of the OutOfZoneNavigationStation class.
   * @param zone The Angular zone.
   * @param inner The inner navigation station.
   */
  constructor(
    private readonly zone: NgZone,
    private readonly inner: NavigationStation) {
  }

  /**
   * Builds the navigation station.
   * @returns A promise that resolves when the navigation station has been built.
   */
  public build(): Promise<void> {
    return this.zone.runOutsideAngular(() => this.inner.build());
  }

  /**
   * Redraws the navigation station.
   * @returns A promise that resolves when the navigation station has been redrawn.
   */
  public redraw(): Promise<void> {
    return this.zone.runOutsideAngular(() => this.inner.redraw());
  }

  /**
   * Disposes the navigation station.
   */
  public dispose() {
    this.inner.dispose();
  }
}

/**
 * A site hooks implementation that runs outside of the Angular zone.
 */
export class OutOfZoneSiteHooks implements SiteHooks {

  /**
   * Creates a new instance of the OutOfZoneSiteHooks class.
   * @param zone The Angular zone.
   * @param inner The inner site hooks.
   */
  constructor(
    private readonly zone: NgZone,
    private readonly inner: SiteHooks) {
  }

  /**
   * @inheritdoc
   */
  public get unitsChangedEvent(): Subject<any> {
    return this.inner.unitsChangedEvent;
  }

  /**
   * @inheritdoc
   */
  canSaveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId): Promise<boolean> {
    return this.zone.run(() => this.inner.canSaveViewerLayout(viewerType, layoutId));
  }

  /**
   * @inheritdoc
   */
  dispose(): void {
    this.inner.dispose();
  }

  /**
   * @inheritdoc
   */
  editChannelUnits(channelName: string, currentUnits: string): Promise<void> {
    return this.zone.run(() => this.inner.editChannelUnits(channelName, currentUnits));
  }

  /**
   * @inheritdoc
   */
  editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout | undefined> {
    return this.zone.run(() => this.inner.editViewerChannels(options));
  }

  /**
   * @inheritdoc
   */
  ensureUnitsLoaded(): Promise<void> {
    return this.zone.run(() => this.inner.ensureUnitsLoaded());
  }

  /**
   * @inheritdoc
   */
  getDefaultChannelUnits(channelName: string): Promise<string> {
    return this.zone.run(() => this.inner.getDefaultChannelUnits(channelName));
  }

  /**
   * @inheritdoc
   */
  getFriendlyErrorAndLog(error: any): string {
    return this.inner.getFriendlyErrorAndLog(error);
  }

  /**
   * @inheritdoc
   */
  getStudyJobName(studyId: string, jobIndex: number): Promise<string> {
    return this.zone.run(() => this.inner.getStudyJobName(studyId, jobIndex));
  }

  /**
   * @inheritdoc
   */
  getStudyName(studyId: string): Promise<string> {
    return this.zone.run(() => this.inner.getStudyName(studyId));
  }

  /**
   * @inheritdoc
   */
  getUserChannelUnits(channelName: string): Promise<string | undefined> {
    return this.zone.run(() => this.inner.getUserChannelUnits(channelName));
  }

  /**
   * @inheritdoc
   */
  getUserChannelUnitsSynchronous(channelName: string): string | undefined {
    return this.zone.run(() => this.inner.getUserChannelUnitsSynchronous(channelName));
  }

  /**
   * @inheritdoc
   */
  getViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds): Promise<any> {
    return this.zone.run(() => this.inner.getViewerDefaultLayoutId(viewerType, layoutIds));
  }

  /**
   * @inheritdoc
   */
  loadViewerLayout(viewerType: string, layoutId?: ResolvedLayoutId): Promise<LoadViewerLayoutResult> {
    return this.zone.run(() => this.inner.loadViewerLayout(viewerType, layoutId));
  }

  /**
   * @inheritdoc
   */
  loadViewerMetadata(viewerType: string, layoutId?: ResolvedLayoutId): Promise<ViewerMetadata | undefined> {
    return this.zone.run(() => this.inner.loadViewerMetadata(viewerType, layoutId));
  }

  /**
   * @inheritdoc
   */
  saveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId, name: string, config: any): Promise<any> {
    return this.zone.run(() => this.inner.saveViewerLayout(viewerType, layoutId, name, config));
  }

  /**
   * @inheritdoc
   */
  saveViewerMetadata(viewerType: string, layoutId: ResolvedLayoutId, viewerMetadata: ViewerMetadata): Promise<any> {
    return this.zone.run(() => this.inner.saveViewerMetadata(viewerType, layoutId, viewerMetadata));
  }

  /**
   * @inheritdoc
   */
  saveViewerLayoutAs(viewerType: string, config: any): Promise<{ viewerId: any; name: string }> {
    return this.zone.run(() => this.inner.saveViewerLayoutAs(viewerType, config));
  }

  /**
   * @inheritdoc
   */
  setViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds, defaultLayoutId: ResolvedLayoutId): Promise<any> {
    return this.zone.run(() => this.inner.setViewerDefaultLayoutId(viewerType, layoutIds, defaultLayoutId));
  }

  /**
   * @inheritdoc
   */
  showStudyJob(studyId: string, jobIndex: number, currentEvent: any): Promise<void> {
    return this.zone.run(() => this.inner.showStudyJob(studyId, jobIndex, currentEvent));
  }
}

/**
 * A URL file loader that runs outside of the Angular zone.
 */
export class OutOfZoneFileLoader implements UrlFileLoader {

  /**
   * Creates a new instance of the OutOfZoneFileLoader class.
   * @param zone The Angular zone.
   * @param inner The inner URL file loader.
   */
  constructor(
    private readonly zone: NgZone,
    private readonly inner: UrlFileLoader) {
  }

  /**
   * @inheritdoc
   */
  loadCarForStudy(studyId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadCarForStudy(studyId));
  }

  /**
   * @inheritdoc
   */
  loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.zone.run(() => this.inner.loadCarForStudyJob(studyId, jobIndex));
  }

  /**
   * @inheritdoc
   */
  loadChannelData(studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.zone.run(() => this.inner.loadChannelData(studyId, jobIndex, simType, channelName, binaryFormat));
  }

  /**
   * @inheritdoc
   */
  loadChartLayout(layoutId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadChartLayout(layoutId));
  }

  /**
   * @inheritdoc
   */
  loadCsv(fileName: string): Promise<any> {
    return this.zone.run(() => this.inner.loadCsv(fileName));
  }

  /**
   * @inheritdoc
   */
  loadExplorationMap(studyId: string): Promise<ExplorationMap> {
    return this.zone.run(() => this.inner.loadExplorationMap(studyId));
  }

  /**
   * @inheritdoc
   */
  loadScalarResultsForSim(studyId: string, jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    return this.zone.run(() => this.inner.loadScalarResultsForSim(studyId, jobIndex, simType));
  }

  /**
   * @inheritdoc
   */
  loadScalarResultsForStudy(studyId: string): Promise<StudyScalarResults> {
    return this.zone.run(() => this.inner.loadScalarResultsForStudy(studyId));
  }

  /**
   * @inheritdoc
   */
  loadTrackForStudy(studyId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadTrackForStudy(studyId));
  }

  /**
   * @inheritdoc
   */
  loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.zone.run(() => this.inner.loadTrackForStudyJob(studyId, jobIndex));
  }

  /**
   * @inheritdoc
   */
  loadVectorMetadata(studyId: string, jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    return this.zone.run(() => this.inner.loadVectorMetadata(studyId, jobIndex, simType));
  }

  /**
   * @inheritdoc
   */
  loadStudyMetadata(studyId: string): Promise<StudyMetadata> {
    return this.zone.run(() => this.inner.loadStudyMetadata(studyId));
  }
}

