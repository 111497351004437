<cs-update-checker></cs-update-checker>
  <!--cs-header-message></cs-header-message-->
<cs-survey-message></cs-survey-message>
<cs-sim-version-status></cs-sim-version-status>
<cs-study-staging-area></cs-study-staging-area>
<cs-results-staging-area></cs-results-staging-area>

<cs-route-resolving-indicator></cs-route-resolving-indicator>
<main class="main-content">
  <router-outlet></router-outlet>
</main>

<cs-worksheet-dock></cs-worksheet-dock>

<cs-download-re-encrypted-config-dialog></cs-download-re-encrypted-config-dialog>
<cs-import-telemetry-dialog></cs-import-telemetry-dialog>
<cs-list-versions-dialog></cs-list-versions-dialog>
<cs-view-job-dialog></cs-view-job-dialog>
<cs-channel-editor-dialog></cs-channel-editor-dialog>
<cs-config-loader-dialog></cs-config-loader-dialog>
<cs-study-loader-dialog></cs-study-loader-dialog>
<cs-worksheet-labels-editor-dialog></cs-worksheet-labels-editor-dialog>
<cs-edit-document-metadata-dialog></cs-edit-document-metadata-dialog>
<cs-compare-config-dialog></cs-compare-config-dialog>
<cs-select-sim-version-dialog></cs-select-sim-version-dialog>
<cs-linting-engine-runner-dialog></cs-linting-engine-runner-dialog>

<cs-support-session-dialog></cs-support-session-dialog>
<cs-set-channel-units-dialog></cs-set-channel-units-dialog>
<cs-confirmation-dialog></cs-confirmation-dialog>
<cs-prompt-dialog></cs-prompt-dialog>
<cs-loading-dialog></cs-loading-dialog>
<cs-context-menu></cs-context-menu>
