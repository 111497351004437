<div class="view-default-configs-component">
  @if (!configs || !!configs.length) {
    <div class="card">
      <h1 class="card-header">
        Defaults
      </h1>
      <div class="card-body">
        <cs-error-message [message]="errorMessage"></cs-error-message>
        @if (!configs) {
          <cs-loader></cs-loader>
        }
        @if (configs) {
          <div>
            <table id="default-configs-table" class="table configs-table last-item">
              <thead>
                <tr>
                  @if (canStage) {
                    <th class="icon-cell"></th>
                  }
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                @for (config of configs; track config; let i = $index) {
                  <tr>
                    @if (canStage) {
                      <td class="icon-cell">
                        <button class="btn btn-secondary btn-icon btn-sm default-stage-button-{{i}} default-stage-name-{{cssSanitize(config.name)}}" (click)="stage(config)">
                          @if (!config.isStaging) {
                            <i class="fa fa-arrow-circle-up"></i>
                          }
                          @if (config.isStaging) {
                            <i class="fa fa-spinner fa-spin"></i>
                          }
                        </button>
                      </td>
                    }
                    <td>
                      @if(navigationUrlTree){
                        <a class="select-config-link" [attr.data-test-id]="cssSanitize(config.name)"
                          [routerLink]="navigationUrlTree(config)">
                          {{config.name}}
                        </a>
                      } @else {
                        <span class="select-config-link" [attr.data-test-id]="cssSanitize(config.name)"
                          (click)="onConfigSelected(config)">
                          {{config.name}}
                        </span>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  }
</div>
