import { Injectable } from '@angular/core';
import * as jsondiffpatch from 'jsondiffpatch';
import { CanopyJson } from './canopy-json.service';
import { createHash } from './create-hash';

export const LARGE_ARRAY_SIZE = 100;
export const LARGE_ARRAY_HASH_PREFIX = 'large-array-hash-';
export const ENCRYPTED_HASH_PREFIX = 'encrypted-hash-';
export const EXPECTED_HASH_LENGTH = 43;

function isEncryptedData(data: any): data is EncryptedData{
  return data.name === 'encrypted';
}

interface EncryptedData{
  name: string;
  description: string;
  metadata: string;
  data: string;
}

@Injectable()
export class CanopyJsonDiffPatch {

  constructor(
    private readonly json: CanopyJson) {
  }

  public getDiff(left: any, right: any, options?: any): DiffResult {
    let hashMap: DiffHashMap = {};
    let replacer = (k: string, v: any) => {
      if (v && Array.isArray(v) && v.length >= LARGE_ARRAY_SIZE) {
        let vString = JSON.stringify(v);
        let hash = createHash(vString);
        hashMap[hash] = v;
        return LARGE_ARRAY_HASH_PREFIX + hash;
      }

      if(v && isEncryptedData(v)){
        let vString = JSON.stringify(v);
        let hash = createHash(vString);
        hashMap[hash] = v;
        return ENCRYPTED_HASH_PREFIX + hash + v.description;
      }

      return v;
    };

    left = this.json.clone(left, replacer);
    right = this.json.clone(right, replacer);

    let jdp = this.create(options);
    let diff = jdp.diff(left, right);

    return new DiffResult(diff, hashMap, left, right);
  }

  public restore(config: any, hashMap: DiffHashMap): any {
    let replacer = (k: string, v: any) => {
      if (v && typeof v === 'string') {
        if(v.startsWith(LARGE_ARRAY_HASH_PREFIX)){
          return hashMap[v.substring(LARGE_ARRAY_HASH_PREFIX.length)];
        }else if(v.startsWith(ENCRYPTED_HASH_PREFIX)){
          return hashMap[v.substring(ENCRYPTED_HASH_PREFIX.length, ENCRYPTED_HASH_PREFIX.length + EXPECTED_HASH_LENGTH)];
        }
      }

      return v;
    };

    return this.json.clone(config, replacer);
  }

  private create(options: any) {
    return jsondiffpatch.create({
      arrays: {
        detectMove: false
      },
      ...(options || {}),
    });
  }
}

export interface DiffHashMap { [hash: string]: any }

export class DiffResult {
  constructor(
    public readonly data: any,
    public readonly hashMap: DiffHashMap,
    public readonly left: any,
    public readonly right: any) {
  }
}
