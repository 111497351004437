/*
 * Usage:
 *   value | toFixed:dp
 * Example:
 *   {{ 2.1234 |  toFixed:2}}
 *   formats to: 2.12
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'toFixed'})
export class ToFixedPipe implements PipeTransform {
  transform(value: number, decimalPlaces: number, minimumDecimalPlaces: number = 0): any {
    //let dp = parseInt(decimalPlaces, undefined);
    //let minDpCount = parseInt(minimumDecimalPlaces, undefined);

    return ToFixedPipe.execute(value, decimalPlaces, minimumDecimalPlaces);
  }

  public static execute(value: number, decimalPlaces: number, minimumDecimalPlaces: number = 0): string{
    if(decimalPlaces === -1){
      return '' + value;
    }

    let result = (+value).toFixed(decimalPlaces);

    let currentDpPosition = result.indexOf('.');
    if(currentDpPosition !== -1){
      let currentDpCount = result.length - currentDpPosition;
      while(currentDpCount > minimumDecimalPlaces && result.endsWith('0')){
        result = result.substring(0, result.length - 1);
      }

      if(result.endsWith('.')){
        result = result.substring(0, result.length - 1);
      }
    }

    return result;

  }
}
