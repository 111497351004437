import {Input, OnDestroy} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {UnitsManager} from './units-manager.service';
import {Subscription} from 'rxjs';
import {SetChannelUnitsDialog} from './set-channel-units-dialog.service';
import {cssSanitize} from '../common/css-sanitize';
import {ToPrecisionPipe} from '../common/to-precision.pipe';

@Component({
  selector: 'cs-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit, OnDestroy {

  @Input() public channelName: string;
  @Input() public units: string;
  @Input() public value: number;
  @Input() public showUnits: boolean;
  @Input() public significantFigures: number = 4;
  @Input() public simpleRender: boolean = false;

  private subscription: Subscription;

  public renderValue: string;
  public isMouseOver: boolean;

  constructor(
    private unitsManager: UnitsManager,
    private setChannelUnitsDialog: SetChannelUnitsDialog){
  }

  ngOnInit(): void {
    this.subscription = this.unitsManager.changed.subscribe(() => {
      this.updateSynchronous();
    });


    if(this.unitsManager.isInitialized) {
      this.updateSynchronous();
    } else {
      this.update();
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  public async setUnits(){
    await this.setChannelUnitsDialog.show(this.channelName, this.units);
  }

  public setMouseOver(value: boolean){
    if(this.showUnits){
      this.isMouseOver = value;
    }
  }

  public updateSynchronous(){
    try {
      if (this.showUnits) {
        let targetUnits = this.unitsManager.getUnitsSynchronous(this.channelName, this.units);
        this.renderValue = this.sanitizeUnits(targetUnits);
      } else {
        let targetValue = this.unitsManager.getValueSynchronous(this.channelName, this.units, this.value);
        this.renderValue = this.formatValue(targetValue);
      }
    } catch (error) {
      this.renderValue = this.showUnits ? this.sanitizeUnits(this.units) : this.formatValue(this.value);
    }
  }

  public async update(){
    try {
      if (this.showUnits) {
        let targetUnits = await this.unitsManager.getUnits(this.channelName, this.units);
        this.renderValue = this.sanitizeUnits(targetUnits);
      } else {
        let targetValue = await this.unitsManager.getValue(this.channelName, this.units, this.value);
        this.renderValue = this.formatValue(targetValue);
      }
    } catch (error) {
      this.renderValue = this.showUnits ? this.sanitizeUnits(this.units) : this.formatValue(this.value);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  private formatValue(input: number): string {
    return ToPrecisionPipe.execute(input, this.significantFigures);
  }

  private sanitizeUnits(input: string): string{
    if(input === '()'){
      return '-';
    }

    return input;
  }
}
