import {IEditChannelsChannelMetadata, PaneChannelLayout, IPaneChannel} from '../../visualizations/site-hooks';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ChannelEditorDialogData} from './channel-editor-dialog.service';
import {ElementRef, Injectable} from '@angular/core';
import {sortBy} from '../../common/sort-by';
import {Timer} from '../../common/timer.service';
import {
  createRegularExpressionFromWildcardString,
  isWildcardString
} from '../../common/create-regular-expression-from-wildcard-string';

/**
 * A factory for creating instances of the channel editor dialog session.
 */
@Injectable()
export class ChannelEditorDialogSessionFactory {

  /**
   * Create a new instance of the channel editor dialog session factory.
   * @param timer The timer service.
   * @param getFriendlyErrorAndLogService The service for getting friendly error messages and logging the errors.
   */
  constructor(
    private readonly timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  /**
   * Create a new instance of the channel editor dialog session.
   * @param data The dialog data.
   * @returns The new session.
   */
  public create(data: ChannelEditorDialogData): ChannelEditorDialogSession{
    return new ChannelEditorDialogSession(
      data,
      this.timer,
      this.getFriendlyErrorAndLog);
  }
}

/**
 * The suffix to append to the description of a channel when the channel name is generic.
 */
export const GENERIC_DESCRIPTION_SUFFIX = ' for any available simulation.';

/**
 * A session for the channel editor dialog.
 */
@Injectable()
export class ChannelEditorDialogSession {

  /**
   * The error message to display.
   */
  public errorMessage: string;

  /**
   * The panes to display.
   */
  public panes: Pane[];

  /**
   * The channel metadata.
   */
  public channelMetadata: IEditChannelsChannelMetadata[];

  /**
   * The filtered channel metadata.
   */
  public filteredChannelMetadata: IEditChannelsChannelMetadata[];

  /**
   * Whether to show the channel selection UI.
   */
  private _showChannelSelection: boolean;

  /**
   * The pane to add a selected channel to.
   */
  public addChannelPaneIndex: number;

  /**
   * Whether to flatten the view, when you can only have one channel per pane (for selecting channels for the
   * parallel coordinates plot).
   */
  public flattenView: boolean;

  /**
   * The filter text box DOM element.
   */
  public filterElement: ElementRef;

  /**
   * Gets whether to show the channel selection UI.
   */
  public get showChannelSelection(): boolean {
    return this._showChannelSelection;
  }

  /**
   * Sets whether to show the channel selection UI.
   */
  public set showChannelSelection(value: boolean){
    this._showChannelSelection = value;
    if(value && this.filterElement){
      // Focus the filter input when the channel selection UI is shown.
      this.focusFilterInput();
    }
  }

  /**
   * Focuses the filter input text box.
   */
  private async focusFilterInput(){
    await this.timer.yield();
    this.filterElement.nativeElement.focus();
    this.filterElement.nativeElement.select();
  }

  /**
   * Create a new instance of the channel editor dialog session.
   * @param dialog The dialog data.
   * @param timer The timer service.
   * @param getFriendlyErrorAndLog The service for getting friendly error messages and logging the errors.
   */
  constructor(
    private readonly dialog: ChannelEditorDialogData,
    private readonly timer: Timer,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  /**
   * Load the session.
   */
  public load() {
    try {
      this.errorMessage = undefined;

      this.flattenView = this.dialog.options.oneChannelPerPane;
      this.channelMetadata = [...this.dialog.options.channels];
      this.channelMetadata.sort(sortBy({name: 'name', primer: (a: string) => a.toLowerCase()}));
      this.filteredChannelMetadata = [...this.channelMetadata];

      let inputPanes = this.dialog.options.panes;
      let outputPanes: Pane[] = [];
      for(let inputPane of inputPanes){
        let outputPane: Pane = {
          channels: [],
          relativeSize: inputPane.relativeSize
        };

        for(let channel of inputPane.channels){
          let channelData = this.channelMetadata.find(v => v.name === channel.name);
          let description = '';
          if(channelData){
            description = channelData.description;
          }
          let paneChannel = new PaneChannel(
            channel.name,
            this.getValueOrDefault(channel.isVisible, true),
            this.getValueOrDefault(channel.isDelta, false),
            description);
          outputPane.channels.push(paneChannel);
        }

        outputPanes.push(outputPane);
      }

      if(this.flattenView && outputPanes.length === 0){
        // We always need at least one pane in the flattened view.
        // (e.g. for the parallel coordinates plot channel selector)
        outputPanes.push({
          channels: [],
          relativeSize: 1
        });
      }

      this.panes = outputPanes;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Handle the filter changed event.
   * @param event The event.
   */
  public onFilterChanged(event: KeyboardEvent) {
    let filter = event.target as HTMLInputElement;
    let filterValue = filter.value;

    let filterEvaluator: (value: IEditChannelsChannelMetadata) => boolean;
    if(!filterValue){
      filterEvaluator = () => true;
    } else if(!isWildcardString(filterValue)){
      filterEvaluator = v => v.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
    } else{
      let r = createRegularExpressionFromWildcardString(filterValue, true, true);
      filterEvaluator = v => r.test(v.name);
    }

    this.filteredChannelMetadata = this.channelMetadata.filter(v => filterEvaluator(v));
  }

  /**
   * Build the result.
   */
  public buildResult(){
    let resultPanes: PaneChannelLayout = [];
    for(let pane of this.panes){
      resultPanes.push({
        channels: pane.channels.map(v => ({
            name: v.name,
            isVisible: v.isVisible,
            isDelta: v.isDelta
          })),
        relativeSize: pane.relativeSize
      });
    }

    resultPanes = resultPanes.filter(v => v.channels.length > 0);

    return resultPanes;
  }

  /**
   * Move a channel up in its pane.
   * @param pane The pane.
   * @param index The index of the channel.
   */
  public moveChannelUp(pane: Pane, index: number) {
    if(index !== 0){
      this.swap(pane.channels, index, index - 1);
    }
  }

  /**
   * Move a channel down in its pane.
   * @param pane The pane.
   * @param index The index of the channel.
   */
  public moveChannelDown(pane: Pane, index: number) {
    if(index !== pane.channels.length - 1){
      this.swap(pane.channels, index, index + 1);
    }
  }

  /**
   * Move a pane up.
   * @param index The index of the pane.
   */
  public movePaneUp(index: number) {
    if(index !== 0){
      this.swap(this.panes, index, index - 1);
    }
  }

  /**
   * Move a pane down.
   * @param index The index of the pane.
   */
  public movePaneDown(index: number) {
    if(index !== this.panes.length - 1){
      this.swap(this.panes, index, index + 1);
    }
  }

  /**
   * Add a pane after the specified index.
   * @param afterIndex The index to add the pane after.
   */
  public addPane(afterIndex: number){
    let referencePane = this.panes[afterIndex];
    let pane: Pane = {
      relativeSize: referencePane.relativeSize,
      channels: []
    };
    this.panes.splice(afterIndex + 1, 0, pane);
    return pane;
  }

  /**
   * Remove a pane.
   * @param index The index of the pane to remove.
   */
  public removePane(index: number){
    this.panes.splice(index, 1);
    if(this.panes.length === 0){
      let pane: Pane = {
        relativeSize: 1,
        channels: []
      };
      this.panes.push(pane);
    }
  }

  /**
   * Show the channel selection UI to add a channel to a pane.
   * @param paneIndex The index of the pane to add the channel to.
   */
  public addChannel(paneIndex: number){
    this.addChannelPaneIndex = paneIndex;
    this.showChannelSelection = true;
  }

  /**
   * Cancel adding a channel.
   */
  public cancelAddChannel(){
    this.addChannelPaneIndex = undefined;
    this.showChannelSelection = false;
  }

  /**
   * Select a channel to add to a pane.
   * @param channelIndex The index of the channel to add.
   * @param keepOpen Whether to keep the channel selection UI open after adding the channel.
   */
  public selectChannel(channelIndex: number, keepOpen: boolean){
    let channel = this.filteredChannelMetadata[channelIndex];

    let pane: Pane;
    if(this.flattenView){
      pane = this.addPane(this.addChannelPaneIndex);
    } else{
      pane = this.panes[this.addChannelPaneIndex];
    }

    pane.channels.push(
      new PaneChannel(
        channel.name,
        true,
        false,
        channel.description));

    if(!keepOpen){
      this.cancelAddChannel();
    }
  }

  /**
   * Remove a channel from a pane.
   * @param pane The pane.
   * @param index The index of the channel to remove.
   */
  public removeChannel(pane: Pane, index: number){
    pane.channels.splice(index, 1);
  }

  /**
   * Swap two items in an array.
   * @param array The array.
   * @param x The index of the first item.
   * @param y The index of the second item.
   */
  private swap(array: any[], x: number, y: number) {
    let b = array[x];
    array[x] = array[y];
    array[y] = b;
  }

  /**
   * Get a value or a default value if the value is undefined.
   * @param value The value.
   * @param defaultValue The default value.
   * @returns The value or the default value if the value is undefined.
   */
  private getValueOrDefault<T>(value: T, defaultValue: T): T{
    if(typeof value === 'undefined'){
      return defaultValue;
    }

    return value;
  }
}

/**
 * A channel in a pane in the channel editor dialog.
 */
export class PaneChannel implements IPaneChannel {

  /**
   * Create a new instance of the pane channel.
   * @param name The name of the channel.
   * @param isVisible Whether the channel is visible.
   * @param isDelta Whether the channel is a delta channel.
   * @param description The description of the channel.
   */
  constructor(
    public name: string,
    public isVisible: boolean,
    public isDelta: boolean,
    public description: string){
  }
}

/**
 * A pane in the channel editor dialog.
 */
interface Pane {
  channels: PaneChannel[];
  relativeSize: number;
}
