import { Injectable, EventEmitter } from '@angular/core';
import { ChartRepository, ChartRepositoryFactory } from './chart-repository.service';
import { SiteHooks, IEditChannelsOptions, PaneChannelLayout, LoadViewerLayoutResult, ViewerMetadata, RequestedLayoutIds } from '../../visualizations/site-hooks';
import {
  DocumentSubType,
} from '../../../generated/api-stubs';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { Timer } from '../../common/timer.service';
import { UnitsManager } from '../../units/units-manager.service';
import { SetChannelUnitsDialog } from '../../units/set-channel-units-dialog.service';
import { Subscription } from 'rxjs';
import { GetPreferredChart } from './get-preferred-chart.service';
import { SetPreferredChart } from './set-preferred-chart.service';
import { ViewJobDialog } from '../jobs/view-job-dialog/view-job-dialog.service';
import { SimVersionDocumentCache } from '../sim-version-document-cache.service';
import { ChannelEditorDialog } from './channel-editor-dialog.service';
import { getJobIdFromJobIndex } from '../../common/get-job-id-from-job-index';
import { LineMouseEvent } from '../../visualizations/viewers/parallel-coordinates-viewer/parallel-coordinates-types';
import { ResultSource, ResultsStagingArea } from '../results-staging-area/results-staging-area.service';
import { StudyInput } from '../../worksheets/study-input';
import { LocalUserChartSettingsManager } from '../../common/local-user-chart-settings-manager';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * Factory for creating CanopySiteHooks instances.
 */
@Injectable()
export class CanopySiteHooksFactory {

  /**
   * Creates a new CanopySiteHooksFactory instance.
   * @param authenticationService The authentication service.
   * @param timer The timer service.
   * @param chartRepositoryFactory The chart repository factory.
   * @param channelEditorDialog The channel editor dialog.
   * @param unitsManager The units manager.
   * @param setChannelUnitsDialog The dialog for setting channel units.
   * @param getPreferredChart The service for getting the user's preferred chart.
   * @param setPreferredChart The service for setting the user's preferred chart.
   * @param viewJobDialog The view job dialog.
   * @param simVersionDocumentCache The sim version document cache.
   * @param resultsStagingArea The results staging area.
   * @param localUserChartSettingsManager The local user chart settings manager.
   * @param getFriendlyErrorAndLogService The service for getting friendly error messages and logging the errors.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly timer: Timer,
    private readonly chartRepositoryFactory: ChartRepositoryFactory,
    private readonly channelEditorDialog: ChannelEditorDialog,
    private readonly unitsManager: UnitsManager,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog,
    private readonly getPreferredChart: GetPreferredChart,
    private readonly setPreferredChart: SetPreferredChart,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly localUserChartSettingsManager: LocalUserChartSettingsManager,
    private readonly getFriendlyErrorAndLogService: GetFriendlyErrorAndLog) {
  }

  /**
   * Creates a new CanopySiteHooks instance.
   * @param fileLoader The file loader.
   */
  public create() {
    const userData = this.authenticationService.userDataSnapshot;

    return new CanopySiteHooks(
      userData.tenant,
      userData.sub,
      this.timer,
      this.chartRepositoryFactory,
      this.channelEditorDialog,
      this.unitsManager,
      this.setChannelUnitsDialog,
      this.getPreferredChart,
      this.setPreferredChart,
      this.viewJobDialog,
      this.simVersionDocumentCache,
      this.resultsStagingArea,
      this.localUserChartSettingsManager,
      this.getFriendlyErrorAndLogService);
  }
}

/**
 * Basic study data.
 */
interface StudyData {

  /**
   * The tenant ID.
   */
  tenantId: string;

  /**
   * The study ID.
   */
  studyId: string;

  /**
   * The study name.
   */
  name: string;
}

/**
 * Canopy implementation of the SiteHooks interface. This allows the visualizations to hook into the wider Canopy Platform.
 */
export class CanopySiteHooks implements SiteHooks {

  /**
   * @inheritdoc
   */
  public unitsChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  /**
   * The chart repository.
   */
  private chartRepository: ChartRepository;

  /**
   * The sim version chart cache.
   */
  private simVersionChartCache: { [key: string]: Promise<StudyInput> } = {};

  /**
   * The units changed subscription.
   */
  private unitsChangedSubscription: Subscription;

  /**
   * The study cache keyed by study id.
   */
  private studyCache: { [studyId: string]: StudyData } = {};

  /**
   * The study job name cache keyed by job ID.
   */
  private studyJobNameCache: { [jobId: string]: string } = {};

  /**
   * Creates a new CanopySiteHooks instance.
   * @param tenantId The tenant ID.
   * @param userId The user ID.
   * @param timer The timer.
   * @param chartRepositoryFactory The chart repository factory.
   * @param channelEditorDialog The channel editor dialog.
   * @param unitsManager The units manager.
   * @param setChannelUnitsDialog The dialog for setting channel units.
   * @param getPreferredChartService The service for getting the user's preferred chart.
   * @param setPreferredChartService The service for setting the user's preferred chart.
   * @param viewJobDialog The view job dialog.
   * @param simVersionDocumentCache The sim version document cache.
   * @param resultsStagingArea The results staging area.
   * @param localUserChartSettingsManager The local user chart settings manager.
   * @param getFriendlyErrorAndLogService The service for getting friendly error messages and logging the errors.
   */
  constructor(
    private readonly tenantId: string,
    private readonly userId: string,
    private readonly timer: Timer,
    private readonly chartRepositoryFactory: ChartRepositoryFactory,
    private readonly channelEditorDialog: ChannelEditorDialog,
    private readonly unitsManager: UnitsManager,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog,
    private readonly getPreferredChartService: GetPreferredChart,
    private readonly setPreferredChartService: SetPreferredChart,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly localUserChartSettingsManager: LocalUserChartSettingsManager,
    private readonly getFriendlyErrorAndLogService: GetFriendlyErrorAndLog) {

    this.chartRepository = this.chartRepositoryFactory.create(tenantId);
    this.unitsChangedSubscription = this.unitsManager.changed.subscribe(() => this.unitsChangedEvent.emit(undefined));
  }

  /**
   * @inheritdoc
   */
  public dispose() {
    this.unitsChangedSubscription.unsubscribe();
  }

  /**
   * @inheritdoc
   */
  public addStudy(
    tenantId: string,
    studyId: string,
    name: string) {
    this.studyCache[studyId] = {
      tenantId,
      studyId,
      name
    };
  }

  /**
   * @inheritdoc
   */
  public addJobName(jobId: string, name: string) {
    this.studyJobNameCache[jobId] = name;
  }

  /**
   * @inheritdoc
   */
  public async saveViewerLayoutAs(viewerType: DocumentSubType, config: any): Promise<SavedChartResult> {

    return this.chartRepository.saveChartAs(viewerType, config);
  }

  /**
   * @inheritdoc
   */
  public async canSaveViewerLayout(viewerType: string, layoutId: UserChartId): Promise<boolean> {
    return layoutId && layoutId.userId && this.userId === layoutId.userId;
  }

  /**
   * @inheritdoc
   */
  public saveViewerLayout(viewerType: DocumentSubType, layoutId: UserChartId, name: string, config: any): Promise<any> {
    return this.chartRepository.saveChart(viewerType, layoutId.configId, name, config);
  }

  /**
   * @inheritdoc
   */
  public async saveViewerMetadata(viewerType: DocumentSubType, layoutId: UserChartId, viewerMetadata: ViewerMetadata): Promise<any> {
    this.localUserChartSettingsManager.setChartSettings(viewerType, layoutId, viewerMetadata.size);
  }

  /**
   * @inheritdoc
   */
  public async loadViewerLayout(viewerType: DocumentSubType, layoutId?: ChartId): Promise<LoadViewerLayoutResult> {

    const studyInput = await this.loadViewerLayoutConfig(viewerType, layoutId);
    return this.convertConfigLoaderResultToLoadedChartResult(studyInput);
  }

  /**
   * @inheritdoc
   */
  public async loadViewerMetadata(viewerType: DocumentSubType, layoutId: UserChartId): Promise<ViewerMetadata | undefined> {
    const chartSettings = this.localUserChartSettingsManager.getChartSettings(viewerType, layoutId);
    return chartSettings ? new ViewerMetadata(chartSettings.size) : undefined;
  }

  /**
   * Loads the chart definition by viewer type and optional chart ID.
   * @param viewerType The viewer type.
   * @param layoutId The chart ID.
   * @returns The loaded chart definition.
   */
  private loadViewerLayoutConfig(viewerType: DocumentSubType, layoutId?: ChartId): Promise<StudyInput> {
    let simVersionChartId = <SimVersionChartId>layoutId;
    let task;
    if (simVersionChartId && typeof simVersionChartId === 'string') {
      let key = `${viewerType}-${layoutId}`;
      task = this.simVersionChartCache[key];
      if (!task) {
        task = this.chartRepository.loadChart(viewerType, layoutId);
        this.simVersionChartCache[key] = task;
      }

      return task;
    }

    return this.chartRepository.loadChart(viewerType, layoutId);
  }

  /**
   * Converts the config loader result to a loaded chart result.
   * @param result The config loader result.
   * @returns The loaded chart result.
   */
  private convertConfigLoaderResultToLoadedChartResult(result: StudyInput): LoadViewerLayoutResult {
    if(!result){
      return undefined;
    }

    let viewerId: ChartId = result.userId
      ? {
        userId: result.userId,
        configId: result.configId
      }
      : result.name;

    return new LoadViewerLayoutResult(viewerId, result.name, result.data);
  }

  /**
   * @inheritdoc
   */
  public async editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout> {
    let result = await this.channelEditorDialog.editChart(options);
    await this.timer.yield(); // Allow the dialog to close before rendering starts.
    return result;
  }

  /**
   * @inheritdoc
   */
  public async getDefaultChannelUnits(channelName: string): Promise<string> {
    let simVersionDocuments = await this.simVersionDocumentCache.get(this.simVersionDocumentCache.lastRequestedVersion);

    let result = simVersionDocuments.units[channelName];

    return result || '()';
  }

  /**
   * @inheritdoc
   */
  public getUserChannelUnits(channelName: string): Promise<string> {
    return this.unitsManager.getUnits(channelName, undefined);
  }

  /**
   * @inheritdoc
   */
  public getUserChannelUnitsSynchronous(channelName: string): string {
    return this.unitsManager.getUnitsSynchronous(channelName, undefined);
  }

  /**
   * @inheritdoc
   */
  public ensureUnitsLoaded() {
    return this.unitsManager.ensureInitialized();
  }

  /**
   * @inheritdoc
   */
  public editChannelUnits(channelName: string, currentUnits: string): Promise<any> {
    return this.setChannelUnitsDialog.show(channelName, currentUnits);
  }

  /**
   * @inheritdoc
   */
  public getViewerDefaultLayoutId(viewerType: DocumentSubType, layoutIds: RequestedLayoutIds): Promise<ChartId> {
    return this.getPreferredChartService.execute(viewerType, layoutIds);
  }

  /**
   * @inheritdoc
   */
  public setViewerDefaultLayoutId(viewerType: DocumentSubType, layoutIds: RequestedLayoutIds, defaultLayoutId: ChartId): Promise<any> {
    return this.setPreferredChartService.execute(this.tenantId, this.userId, viewerType, layoutIds, defaultLayoutId);
  }

  /**
   * @inheritdoc
   */
  public getStudyName(studyId: string): Promise<string> {
    let studyData = this.studyCache[studyId];
    let name = studyData ? studyData.name : 'Unnamed Study';
    return Promise.resolve(name);
  }

  /**
   * @inheritdoc
   */
  public async getStudyJobName(studyId: string, jobIndex: number): Promise<string> {
    let studyName = await this.getStudyName(studyId);
    return studyName + ' / ' + (this.studyJobNameCache[getJobIdFromJobIndex(studyId, jobIndex)] || 'Unnamed');
  }

  /**
   * @inheritdoc
   */
  public showStudyJob(studyId: string, jobIndex: number, currentEvent: LineMouseEvent): Promise<any> {
    let event = currentEvent as LineMouseEvent;
    let mouseEvent = event.sourceEvent;
    let studyData = this.studyCache[studyId];
    if(!!(mouseEvent.ctrlKey || mouseEvent.metaKey)){
      let jobId = this.getJobId(studyId, jobIndex);
      let jobName = this.studyJobNameCache[jobId] || `Job Index ${event.lineIndex}`;
      this.resultsStagingArea.addSource(
        new ResultSource(studyData.tenantId, studyId, studyData.name, jobId, jobName));
      return Promise.resolve();
    } else {
      return this.viewJobDialog.show(
        studyData.tenantId,
        studyId,
        this.getJobId(studyId, jobIndex));
    }
  }

  /**
   * @inheritdoc
   */
  public getFriendlyErrorAndLog(error: any): string {
    return this.getFriendlyErrorAndLogService.execute(error);
  }

  private getJobId(studyId: string, jobIndex: number): string {
    return `${studyId}-${jobIndex}`;
  }
}

/**
 * A user chart ID, for a custom chart config a user has saved.
 */
export interface UserChartId {

  /**
   * The user ID.
   */
  userId: string;

  /**
   * The config ID.
   */
  configId: string;
}

/**
 * A default chart ID, for a default chart config that is provided as part of the current sim version.
 */
export type SimVersionChartId = string;

/**
 * A chart ID, which can be either a user chart ID or a sim version chart ID.
 */
export type ChartId = UserChartId | SimVersionChartId;

/**
 * The result of saving a chart.
 */
export interface SavedChartResult {
  viewerId: ChartId;
  name: string;
}

/**
 * The result of loading a chart.
 */
export interface LoadedChartResult {
  viewerId: ChartId;
  name: string;
  config: any;

}
